.p-clinic {
  padding-block-end: 180px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    padding-block-end: 100px;
  }

  header.header:not(.header--sticky) {
    background-color: var(--emu-semantic-colors-primary-lightblue);
  }

  &-container {
    > .container:first-of-type > .aaaem-container {
      position: relative;

      // Necessary to have the correct height and space for the text positioned absolutely next to the ICL Detail
      @include mq($emu-semantic-sizing-breakpoints-large) {
        min-height: 1650px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        min-height: 1800px;
      }
    }

    .icldetail {
      + .text {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          inset-inline-end: 15px;
          inset-block-start: 535px;
          margin-block-end: 90px;
          max-width: 445px;
          position: absolute;
          // 45px is added to account for the absolute shift to the right and left and right padding
          width: calc(50% - 45px);
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          // 15px is added to account for the absolute shift to the right
          inset-inline-start: calc(50% + 15px);
          inset-inline-end: auto;
        }

        .aaaem-text {
          background-color: var(--emu-semantic-colors-secondary-blue-400);
          margin-block-start: 10px;
          padding-block-end: 60px;
          padding-inline-start: 15px;
          padding-inline-end: 15px;
          padding-block-start: 60px;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            margin-block-start: 0;
            padding-block-end: 30px;
            padding-inline-start: 30px;
            padding-inline-end: 30px;
            padding-block-start: 30px;
          }

          h3,
          h4,
          h5,
          p {
            margin-block-end: 20px;
          }

          h5 {
            &::before {
              background-color: var(--emu-semantic-colors-primary-lightblue);
              content: "";
              display: block;
              height: 1px;
              margin-block-end: 30px;
              margin-block-start: 30px;
              width: 40px;
            }
          }
        }
      }
    }
  }
}