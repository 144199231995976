.p-clinics,
.p-clinic {
  .modal-content {
    background-color: var(--emu-common-colors-white);
    text-align: center;
    padding-block-start: 20px;
    padding-inline-end: 20px;
    padding-block-end: 40px;
    padding-inline-start: 20px;
    position: absolute;
    inset-block-end: 0;
    margin-block-end: 0;
    width: 100%;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      max-width: 610px;
      position: relative;
      inset-block-end: initial;
      margin-block-end: 15%;

    }

    h1 {
      font: var(--emu-semantic-typography-wide-headings-xl);
      color: var(--emu-semantic-colors-primary-blue-300);
      letter-spacing: -2px;
    }

    .close {
      color: var(--emu-semantic-colors-primary-blue-300);
      font-size: var(--emu-semantic-font-sizes-narrow-xl);
    }

    &__header,
    &__message {
      max-width: 350px;
      margin-inline-end: auto;
      margin-inline-start: auto;
    }

    &__header {
      margin-block-start: 40px;
    }

    &__message {
      color: var(--emu-component-ingredients-text-text-color-light);
      padding-block-start: 20px;
      padding-inline-end: 20px;
      padding-block-end: 20px;
      padding-inline-start: 20px;
    }

    &__cta {
      border: 1px solid;
      border-radius: 25px;
      cursor: pointer;
      font-weight: 500;
      padding-block-end: 13px;
      padding-inline-start: 27px;
      padding-inline-end: 27px;
      padding-block-start: 13px;

      &-container {
        display: flex;
        justify-content: center;
        padding-block-end: 20px;
        padding-block-start: 20px;
      }

      &--confirm {
        background-color: var(--emu-component-actions-button-color-background-primary-filled-dark);
        color: var(--emu-component-actions-button-text-color-primary-filled-dark);
        margin-inline-end: 10px;

        &:active,
        &:focus,
        &:hover {
          background: linear-gradient(270deg, var(--emu-semantic-colors-primary-blue-300) 0%, var(--emu-semantic-colors-primary-blue-200) 100%);
        }
      }

      &--cancel {
        border-color: var(--emu-semantic-colors-primary-blue-300);
        color: var(--emu-semantic-colors-primary-blue-300);

        &:active,
        &:focus,
        &:hover {
          background: linear-gradient(270deg, var(--emu-semantic-colors-transparent-white-300) 0%, var(--emu-semantic-colors-transparent-primary-blue-300-a30) 100%);
        }
      }
    }
  }

  .iclfeed__modal,
  .icldetail__modal {
    .modal-content {
      > .container {
        margin-block-start: 30px;

        .cmp-title__text {
          margin-block-end: 20px;
          text-align: center;
        }

        .cmp-text {
          margin-block-end: 20px;
        }
      }
    }

    .modal-cta-container {
      [data-ref="close-cta"] {
        order: 1;
      }
    }
      
    .modal__cta--confirm {
      border: 1px solid var(--emu-semantic-colors-primary-lightblue);
      border-radius: 25px;
      color: var(--emu-semantic-colors-primary-lightblue);
      display: block;
      padding-block-end: 1px;
      padding-inline-start: 20px;
      padding-inline-end: 20px;
      padding-block-start: 5px;
    }

    .modal__cta--cancel {
      padding-block-end: 2px;
      padding-block-start: 6px;

      &:hover {
        background: linear-gradient(to right, var(--emu-semantic-colors-secondary-blue-200), var(--emu-semantic-colors-primary-lightblue));
      }
      
      &:focus {
        box-shadow: 0 0 4px 4px var(--emu-semantic-colors-secondary-blue-200);
      }
    }
  }
}