.findaclinic__container {
  background-color: var(--emu-semantic-colors-primary-lightblue);
  margin-block-end: -30px;

  .findaclinic-hero {
    background-color: var(--emu-common-colors-transparent);
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    max-width: 100%;
    padding-block-end: 0;
    position: relative;
  
    @include mq($emu-semantic-sizing-breakpoints-large) {
      flex-direction: row;
      max-width: var(--emu-semantic-sizing-content-max-width);
      padding-block-start: 80px;
    }
    
    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-inline-end: auto;
      margin-inline-start: auto;
      padding-block-start: 110px;
    }
  
    > div:nth-child(1) {
      width: 100%;
  
      @include mq($emu-semantic-sizing-breakpoints-large) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
      }
    }
  
    > div:nth-child(2) {
      width: 100%;
  
      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: 50%;
      }
    }
  
    .findaclinic-hero-text {
      animation-name: fadeIn;
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      display: flex;
      flex-direction: column;
      padding-inline-end: 15px;
      padding-inline-start: 15px;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        padding-inline-end: 0;
        padding-inline-start: 0;
      }
  
      .title {
        .emu-title__text {
          color: var(--emu-common-colors-white);
          margin-block-end: 40px;
          margin-block-start: 40px;
          max-width: 290px;
  
          @include mq($emu-semantic-sizing-breakpoints-large) {
            margin-block-end: 30px;
          }
  
          @include mq($emu-semantic-sizing-breakpoints-xx-large) {
            margin-block-start: 0;
            margin-block-end: 30px;
            max-width: 450px;
          }
        }
      }
  
      .locationservices {
        margin-block-end: 60px;

        button {
          background-color: var(--emu-semantic-colors-none);
          border: 1px solid var(--emu-common-colors-white);
          border-radius: 25px;
          color: var(--emu-common-colors-white);
          cursor: pointer;
          display: inline-block;
          font-family: var(--emu-semantic-font-families-body);
          font-size: 18px;
          height: 40px;
          line-height: 32px;
          overflow: hidden;
          padding-block-start: 4px;
          padding-inline-end: 20px;
          padding-inline-start: 20px;
          position: relative;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: fit-content;

          &:active,
          &:focus,
          &:hover {
            background-color: var(--emu-component-actions-button-color-background-primary-outline-hover-dark);
            border-color: var(--emu-component-actions-button-color-border-primary-outline-hover-dark);
            color: var(--emu-component-actions-button-text-color-primary-outline-hover-dark);
            outline-color: var(--emu-component-actions-button-color-outline-color-primary-outline-hover-dark);
          }
        }
      }
    }
  
    .aaaem-container {
      background-color: var(--emu-common-colors-transparent);
    }
  
    .image {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .aaaem-image {
        width: 100%;
      }
      
      @include mq($emu-semantic-sizing-breakpoints-large) {
        animation-delay: 0.3s;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;
        animation-name: revealFromBottom;
        opacity: 0;
      }
  
      img {
        display: block;
        object-fit: cover;
        width: 100%;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-inline-start: auto;
          max-width: 445px;
          padding-inline-end: 15px;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          padding-inline-end: 0;
        }
      }
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .findaclinic__container {
    .findaclinic-hero {
      .findaclinic-hero-text {
        .locationservices {  
          button {
            font-family: var(--emu-semantic-font-families-body-assistant);
          }
        }
      }
    }
  }
}