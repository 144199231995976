.tabpanel__container-map {
  .options {
    inset-block-start: 80px;
    inset-inline-start: 50%;
    position: absolute;
    z-index: var(--emu-common-other-z-index-tabs);

    @include mq($emu-semantic-sizing-breakpoints-large) {
      inset-block-start: 10px;
    }
  }
}

.cmp-form-options {
  &__field {
    &--checkbox {
      &:not(:checked) + span::after {
        opacity: 0;
        transform: scale(0);
      }

      &:checked,
      &:not(:checked) {
        height: 18px;
        inset-block-start: 4px;
        inset-inline-start: 4px;
        opacity: 1;
        position: absolute;
        width: 18px;

        + span {
          color: var(--emu-semantic-colors-text-light);
          cursor: pointer;
          display: block;
          font-size: 14px;
          line-height: 20px;
          padding-inline-start: 28px;
          position: relative;
        }
      }

      + span::before,
      &:not(:checked) + span::before {
        background-color: var(--emu-common-colors-white);
        border: 1px solid var(--emu-semantic-colors-primary-lightblue);
        border-radius: 4px;
        content: "";
        height: 20px;
        inset-block-start: 0;
        inset-inline-start: 0;
        position: absolute;
        width: 20px;
      }

      &:checked + span::after,
      &:not(:checked) + span::after {
        background-color: var(--emu-semantic-colors-primary-lightblue);
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cpath d='M4 10.5 8.5 15 16 6' stroke='white' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 14px;
        border-radius: 4px;
        content: "";
        height: 20px;
        inset-block-start: 0;
        inset-inline-start: 0;
        position: absolute;
        transition: all .2s ease;
        width: 20px;
      }
    }
  }

  &--checkbox {
    background-color: var(--emu-common-colors-white);
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 var(--emu-semantic-colors-transparent-black-300);
    inset-inline-start: -50%;
    margin-block-end: 10px;
    margin-inline-end: 10px;
    margin-inline-start: 10px;
    padding-block-end: 7px;
    padding-block-start: 7px;
    padding-inline-end: 7px;
    padding-inline-start: 7px;
    position: relative;
  }
}
