.c-mediatext {
  display: grid;
  grid-row-gap: 30px;
  margin-block-end: 30px;
  margin-block-start: 60px;
  max-width: var(--emu-semantic-sizing-content-max-width);
  padding-inline-start: 15px;
  padding-inline-end: 15px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    grid-template-columns: 50%;
  }

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-block-end: 60px;
    margin-block-start: 80px;
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
  .title-highlighted {
    //for slide animation
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    opacity: 0;
    position: relative;

    .cmp-title__text {
      background-color: var(--emu-semantic-colors-secondary-blue-400);
      position: relative;
      padding-top: 30px; //to adjust the conatiner size
      padding-bottom: 20px; //to adjust the conatiner size
      padding-right: 15px; //to adjust the conatiner size
      padding-left: 20px; //to adjust the conatiner size
      font-family: var(--emu-semantic-font-families-body);
      color: var(--emu-semantic-colors-primary-lightblue);
      font-size: var(
        --emu-semantic-font-sizes-narrow-medium
      ); //to match the other title
      line-height: var(
        --emu-semantic-line-heights-narrow-medium
      ); //to match the other title
      font-weight: var(
        --emu-semantic-font-weight-bold
      ); //to match the other title
      text-align: start;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        padding-top: 50px; // to match the size of conatiner with other titles
        padding-bottom: 40px; // to match the size of conatiner with other titles
        padding-right: var(--emu-common-spacing-none);
        padding-left: 40px; // to match the size of conatiner with other titles
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-semantic-line-heights-wide-medium);
        text-align: end;
      }
    }
    &::before {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        content: "";
        height: 100%;
        width: 50vw;
        right: var(--emu-common-spacing-none);
        top: var(--emu-common-spacing-none);
        position: absolute;
        background-color: var(--emu-semantic-colors-secondary-blue-400);
      }
    }
    &::after {
      content: "";
      height: 100%;
      transform: translateX(100%); //in mobile the container is right aligend
      width: 90vw;
      top: var(--emu-common-spacing-none);
      position: absolute;
      background-color: var(--emu-semantic-colors-secondary-blue-400);

      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: 30px;
        right: var(--emu-common-spacing-none);
      }
    }

    &--right {
      .cmp-title__text {
        text-align: start;
        @include mq($emu-semantic-sizing-breakpoints-large) {
          padding-right: 40px;
          padding-left: var(
            --emu-common-spacing-none
          ); // to match the size of conatiner with other titles
        }
      }
      &::after {
        content: "";
        transform: translateX(-100%);
        @include mq($emu-semantic-sizing-breakpoints-large) {
          width: 50vw;
          transform: translateX(100%);
        }
      }
      &::before {
        content: "";
        width: 90vw;
        transform: translateX(-100%);

        @include mq($emu-semantic-sizing-breakpoints-large) {
          width: 30px;
          z-index: var(--emu-common-other-z-index-layer);
          left: var(--emu-common-spacing-none);
          right: var(--emu-common-spacing-none);
          top: var(--emu-common-spacing-none);
        }
      }
    }
  }
  > .title,
  > .text,
  > .button {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      max-width: 85%;
      padding-inline-start: 15%;
    }
  }

  > .title {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      grid-area: 2/2/2;
    }
  }

  > .image {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    display: flex;
    margin-inline-start: -15px;
    opacity: 0;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      grid-area: 1/1/6/1;
      margin-block-end: 0;
      margin-inline-end: 20px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-inline-start: -70px;
    }

    .cmp-image,
    .cmp-image__image {
      width: 100%;
    }
  }

  > .text {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      grid-row: 3/4;
    }

    p {
      margin-block-end: 15px;
    }
  }

  > .button {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      grid-row: 4/5;
    }
  }

  &.c-mediatext--rtl {
    > .title,
    > .text,
    > .button {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        max-width: 85%;
        padding-inline-start: 0;
        padding-inline-end: 25px;
      }
    }

    > .title {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-area: auto;
      }
    }

    > .image {
      margin-inline-start: 0;
      margin-inline-end: -15px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-area: 1/2/6/2;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-inline-start: 0;
      }
    }
  }

  &.c-mediatext--texthighlighted {
    > .title {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-area: 1/1;
        max-width: none;
        padding-inline-start: 0;
      }

      + .text {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-block-start: 40px;
        }
      }
    }

    > .text {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-area: 2/2;
        max-width: none;
      }

      .text-highlighted {
        animation-delay: 0.3s;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;
        opacity: 0;

        h5 {
          background-color: var(--emu-semantic-colors-secondary-blue-400);
          font-family: var(--emu-semantic-font-families-body);
          margin-inline-start: 0;
          max-width: 100%;
          padding-block-end: 20px;
          padding-inline-start: 15px;
          padding-inline-end: 20px;
          padding-block-start: 30px;
          position: relative;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            padding-block-end: 40px;
            padding-inline-start: 0;
            padding-inline-end: 40px;
            padding-block-start: 50px;
          }

          sup {
            font-size: 10px;
          }

          &::before {
            background-color: var(--emu-semantic-colors-secondary-blue-400);
            inset-block-end: 0;
            content: "";
            display: block;
            inset-inline-start: 1px;
            position: absolute;
            inset-block-start: 0;
            transform: translateX(-100%);
            width: 50vw;
            z-index: var(--emu-common-other-z-index-layer);

            @include mq($emu-semantic-sizing-breakpoints-large) {
              width: 30px;
            }
          }

          &::after {
            @include mq($emu-semantic-sizing-breakpoints-large) {
              background-color: var(--emu-semantic-colors-secondary-blue-400);
              inset-block-end: 0;
              content: "";
              display: block;
              inset-inline-start: 100%;
              position: absolute;
              inset-block-start: 0;
              width: 50vw;
              z-index: var(--emu-common-other-z-index-layer);
            }
          }
        }
      }
    }

    > .image {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        grid-area: 2/1/5/1;
      }

      + .text {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          grid-area: 3/2;
          margin-block-start: 10px;
        }
      }
    }
  }

  &.js-emu-inview {
    > .image {
      animation-name: revealFromBottom;
    }

    .text-highlighted {
      animation-name: slideIn;
    }
    .title-highlighted {
      animation-name: slideIn;
      &.first{
      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-top: 40px;
      }
      }
    }
  }
}
// Country overrides
html[lang="he"],
html[lang="he_il"] {
  .c-mediatext {
    &.c-mediatext--texthighlighted {
      > .text {
        .text-highlighted {
          h5 {
            font-family: var(--emu-semantic-font-families-body-assistant);
          }
        }
      }
    }
  }
}
