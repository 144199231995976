.p-results {
  &-container {
    overflow: hidden;
  }

  .cmp-teaser {
    margin-block-end: 0;
  }

  .c-keytakeaways {
    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-block-start: -60px;
    }
  }
}