.c-find-the-clinic-video {
  margin-block-end: 60px;
  margin-block-start: 60px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-block-end: 120px;
    margin-block-start: 120px;
  }

  &.js-emu-inview {
    .c-find-the-clinic-video__content {
      animation-name: slideIn;
    }
  }

  .container {
    padding-inline-end: 15px;
    padding-inline-start: 15px;
  }

  &__content {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    background-color: var(--emu-semantic-colors-primary-lightblue);
    border-radius: 10px;
    margin-block-end: 0;
    margin-block-start: 0;
    margin-inline-end: auto;
    margin-inline-start: auto;
    opacity: 0;
    padding-block-end: 20px;
    padding-block-start: 30px;
    padding-inline-end: 30px;
    padding-inline-start: 30px;
    width: 100%;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      max-width: 540px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      max-width: 730px;
      padding-block-end: 30px;
      padding-block-start: 30px;
      padding-inline-end: 35px;
      padding-inline-start: 35px;
    }
  }

  &__heading > .emu-title__text {
    color: var(--emu-common-colors-white);
    margin-block-end: 40px;
    margin-block-start: 0;
    text-align: center;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-block-end: 30px;
    }
  }

  &__search {
    .emu-location-services__location-input-wrapper {
      .emu-location-services__location-input {
        background-color: var(--emu-semantic-colors-transparent-white-10);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg stroke='%23fff'%3E%3Cg%3E%3Cg transform='translate(-525.000000, -263.000000) translate(525.000000, 263.000000) translate(3.000000, 3.000000)'%3E%3Ccircle cx='5.5' cy='5.5' r='5'/%3E%3Cpath d='M13 13L9 9'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        border-block-end-color: var(--emu-common-colors-white);
        color: var(--emu-common-colors-white);
  
        &::placeholder {
          color: var(--emu-common-colors-white);
        }
      }
    }

    .emu-location-services__search-submit-cta {
      display: none;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        border: 1px solid var(--emu-common-colors-white);
        color: var(--emu-common-colors-white);
        display: block;
      }

      &:hover {
        background: var(--emu-component-actions-button-color-background-primary-outline-hover-dark);
      }

      &:active {
        background-color: var(--emu-component-actions-button-color-background-primary-outline-active-dark);
      }

      &:focus {
        background-color: var(--emu-component-actions-button-color-background-primary-outline-focus-dark);
        box-shadow: none;
      }
    }

    .emu-location-services__current-location-btn {
      color: var(--emu-common-colors-white);
    }
  }
}