
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #FFFFFF !default;
$emu-semantic-colors-surface-dark: #000000 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4) !default;
$emu-semantic-colors-actions-primary-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-dark: #CC0700 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-text-light: #071D49 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-colors-primary-darkblue: #071D49 !default;
$emu-semantic-colors-primary-lightblue: #00A3E0 !default;
$emu-semantic-colors-primary-orange: #FF6A39 !default;
$emu-semantic-colors-secondary-blue-100: #027BB2 !default;
$emu-semantic-colors-secondary-blue-200: #7FD1EF !default;
$emu-semantic-colors-secondary-blue-300: #83B9E6 !default;
$emu-semantic-colors-secondary-blue-400: #E6F6FC !default;
$emu-semantic-colors-secondary-blue-500: #E5F5FB !default;
$emu-semantic-colors-secondary-blue-600: #EAF5FC !default;
$emu-semantic-colors-secondary-red-100: #FFCEC2 !default;
$emu-semantic-colors-secondary-grey-100: #CCCCCC !default;
$emu-semantic-colors-secondary-grey-200: #ECE3DE !default;
$emu-semantic-colors-secondary-grey-300: #979797 !default;
$emu-semantic-colors-transparent-white-10: rgb(255, 255, 255, 0.1) !default;
$emu-semantic-colors-transparent-white-35: rgb(255, 255, 255, 0.35) !default;
$emu-semantic-colors-transparent-white-50: rgb(255, 255, 255, 0.5) !default;
$emu-semantic-colors-transparent-white-60: rgb(255, 255, 255, 0.5) !default;
$emu-semantic-colors-transparent-white-80: rgb(255, 255, 255, 0.8) !default;
$emu-semantic-colors-transparent-black-300: rgba(0, 0, 0, 0.3) !default;
$emu-semantic-colors-transparent-black-600: rgba(0, 0, 0, 0.6) !default;
$emu-semantic-colors-transparent-blue-10: rgb(0, 163, 224, 0.1) !default;
$emu-semantic-colors-transparent-grey-300: rgba(117, 120, 123, 0.3) !default;
$emu-semantic-font-weight-bold: 500 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 300 !default;
$emu-semantic-font-families-body: Founders-Grotesk, sans-serif !default;
$emu-semantic-font-families-heading: Saol-Standard, sans-serif !default;
$emu-semantic-font-families-body-assistant: Assistant !default;
$emu-semantic-font-families-heading-assistant: Assistant !default;
$emu-semantic-font-families-mono: Courier !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 8px !default;
$emu-semantic-spacing-vertical: 8px !default;
$emu-semantic-spacing-one-line-height-wide: 45px !default;
$emu-semantic-spacing-one-line-height-narrow: 25px !default;
$emu-semantic-font-sizes-narrow-medium: 18px !default;
$emu-semantic-font-sizes-narrow-large: 24px !default;
$emu-semantic-font-sizes-narrow-xl: 27px !default;
$emu-semantic-font-sizes-narrow-xxl: 41px !default;
$emu-semantic-font-sizes-narrow-xxxl: 61px !default;
$emu-semantic-font-sizes-wide-medium: 27px !default;
$emu-semantic-font-sizes-wide-large: 41px !default;
$emu-semantic-font-sizes-wide-xl: 45px !default;
$emu-semantic-font-sizes-wide-xxl: 52px !default;
$emu-semantic-font-sizes-wide-xxxl: 91px !default;
$emu-semantic-line-heights-narrow-medium: 25px !default;
$emu-semantic-line-heights-narrow-large: 30px !default;
$emu-semantic-line-heights-narrow-xl: 35px !default;
$emu-semantic-line-heights-narrow-xxl: 45px !default;
$emu-semantic-line-heights-narrow-xxxl: 60px !default;
$emu-semantic-line-heights-wide-large: 35px !default;
$emu-semantic-line-heights-wide-medium: 45px !default;
$emu-semantic-line-heights-wide-xl: 50px !default;
$emu-semantic-line-heights-wide-xxl: 70px !default;
$emu-semantic-line-heights-wide-xxxl: 90px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 390px !default;
$emu-semantic-sizing-breakpoints-medium: 576px !default;
$emu-semantic-sizing-breakpoints-large: 768px !default;
$emu-semantic-sizing-breakpoints-x-large: 992px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1200px !default;
$emu-semantic-sizing-one-line-height-wide: 45px !default;
$emu-semantic-sizing-one-line-height-narrow: 25px !default;
$emu-semantic-sizing-content-max-width: 1140px !default;
$emu-semantic-sizing-modal-max-width: 1016px !default;
$emu-semantic-typography-narrow-headings-xxxl: 300 61px/60px Saol-Standard, sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 300 41px/45px Saol-Standard, sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 300 27px/35px Saol-Standard, sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 300 18px/25px Saol-Standard, sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 500 18px/25px Saol-Standard, sans-serif !default;
$emu-semantic-typography-narrow-headings-assistant-xxxl: 300 61px/60px Assistant !default;
$emu-semantic-typography-narrow-headings-assistant-xxl: 300 41px/45px Assistant !default;
$emu-semantic-typography-narrow-headings-assistant-xl: 300 27px/35px Assistant !default;
$emu-semantic-typography-narrow-headings-assistant-large: 300 18px/25px Assistant !default;
$emu-semantic-typography-narrow-headings-assistant-medium: 500 18px/25px Assistant !default;
$emu-semantic-typography-narrow-body-regular: 300 16px/30px Founders-Grotesk, sans-serif !default;
$emu-semantic-typography-narrow-body-assistant-regular: 300 16px/30px Assistant !default;
$emu-semantic-typography-wide-headings-xxxl: 300 91px/90px Saol-Standard, sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 300 52px/70px Saol-Standard, sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 300 45px/50px Saol-Standard, sans-serif !default;
$emu-semantic-typography-wide-headings-large: 300 41px/35px Saol-Standard, sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 500 27px/45px Saol-Standard, sans-serif !default;
$emu-semantic-typography-wide-headings-assistant-xxxl: 300 91px/90px Assistant !default;
$emu-semantic-typography-wide-headings-assistant-xxl: 300 52px/70px Assistant !default;
$emu-semantic-typography-wide-headings-assistant-xl: 300 45px/50px Assistant !default;
$emu-semantic-typography-wide-headings-assistant-large: 300 41px/35px Assistant !default;
$emu-semantic-typography-wide-headings-assistant-medium: 500 27px/45px Assistant !default;
$emu-semantic-typography-wide-body-regular: 300 22px/30px Founders-Grotesk, sans-serif !default;
$emu-semantic-typography-wide-body-assistant-regular: 300 22px/30px Assistant !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        'darkblue': $emu-semantic-colors-primary-darkblue,
        'lightblue': $emu-semantic-colors-primary-lightblue,
        'orange': $emu-semantic-colors-primary-orange
      ),
      'secondary': (
        'blue': (
          '100': $emu-semantic-colors-secondary-blue-100,
          '200': $emu-semantic-colors-secondary-blue-200,
          '300': $emu-semantic-colors-secondary-blue-300,
          '400': $emu-semantic-colors-secondary-blue-400,
          '500': $emu-semantic-colors-secondary-blue-500,
          '600': $emu-semantic-colors-secondary-blue-600
        ),
        'red': (
          '100': $emu-semantic-colors-secondary-red-100
        ),
        'grey': (
          '100': $emu-semantic-colors-secondary-grey-100,
          '200': $emu-semantic-colors-secondary-grey-200,
          '300': $emu-semantic-colors-secondary-grey-300
        )
      ),
      'transparent': (
        'white': (
          '10': $emu-semantic-colors-transparent-white-10,
          '35': $emu-semantic-colors-transparent-white-35,
          '50': $emu-semantic-colors-transparent-white-50,
          '60': $emu-semantic-colors-transparent-white-60,
          '80': $emu-semantic-colors-transparent-white-80
        ),
        'black': (
          '300': $emu-semantic-colors-transparent-black-300,
          '600': $emu-semantic-colors-transparent-black-600
        ),
        'blue': (
          '10': $emu-semantic-colors-transparent-blue-10
        ),
        'grey': (
          '300': $emu-semantic-colors-transparent-grey-300
        )
      )
    ),
    'fontWeight': (
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'bodyAssistant': $emu-semantic-font-families-body-assistant,
      'headingAssistant': $emu-semantic-font-families-heading-assistant,
      'mono': $emu-semantic-font-families-mono
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'medium': $emu-semantic-line-heights-narrow-medium,
        'large': $emu-semantic-line-heights-narrow-large,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'large': $emu-semantic-line-heights-wide-large,
        'medium': $emu-semantic-line-heights-wide-medium,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      ),
      'contentMaxWidth': $emu-semantic-sizing-content-max-width,
      'modalMaxWidth': $emu-semantic-sizing-modal-max-width
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium,
          'assistant': (
            'xxxl': $emu-semantic-typography-narrow-headings-assistant-xxxl,
            'xxl': $emu-semantic-typography-narrow-headings-assistant-xxl,
            'xl': $emu-semantic-typography-narrow-headings-assistant-xl,
            'large': $emu-semantic-typography-narrow-headings-assistant-large,
            'medium': $emu-semantic-typography-narrow-headings-assistant-medium
          )
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular,
          'assistant': (
            'regular': $emu-semantic-typography-narrow-body-assistant-regular
          )
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium,
          'assistant': (
            'xxxl': $emu-semantic-typography-wide-headings-assistant-xxxl,
            'xxl': $emu-semantic-typography-wide-headings-assistant-xxl,
            'xl': $emu-semantic-typography-wide-headings-assistant-xl,
            'large': $emu-semantic-typography-wide-headings-assistant-large,
            'medium': $emu-semantic-typography-wide-headings-assistant-medium
          )
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular,
          'assistant': (
            'regular': $emu-semantic-typography-wide-body-assistant-regular
          )
        )
      )
    )
  )
);
