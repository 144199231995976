.cmp-teaser {
  margin-block-end: 30px;

  &.has-media {
    height: calc(100vh - 50px);

    @include aem-editor-view() {
      height: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-large) {
      height: 100vh;

      @include aem-editor-view() {
        height: auto;
      }
    }

    &:not(.has-video) {
      height: 50vh;

      @include aem-editor-view() {
        height: auto;
      }

      @include mq($emu-semantic-sizing-breakpoints-medium) {
        height: 75vh;

        @include aem-editor-view() {
          height: auto;
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        height: 100vh;

        @include aem-editor-view() {
          height: auto;
        }
      }
    }
  }

  &.has-video {
    &::after {
      .p-home & {
        background: linear-gradient(to bottom, var(--emu-semantic-colors-primary-lightblue) 50%, var(--emu-semantic-colors-primary-orange) 50%);
        inset-block-end: -30px;
        content: "";
        display: block;
        height: 60px;
        inset-inline-start: 50%;
        position: absolute;
        width: 1px;
        z-index: var(--emu-common-other-z-index-layer);
      }
    }
  }

  &:not(.has-video) {
    &::after {
      background-color: var(--emu-semantic-colors-transparent-black-300);
      content: "";
      height: 100%;
      inset-inline-start: 0;
      position: absolute;
      inset-block-start: 0;
      width: 100%;
    }
  }

  &__description {
    max-width: 100%;

    h1 {
      font-size: 51px;

      sup {
        font-size: 18px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          font-size: 24px;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          font-size: 35px;
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-large) {
        font-size: 61px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        font-size: 91px;
      }
    }
  }

  .content {
    z-index: var(--emu-common-other-z-index-layer);

    &-container {
      max-width: var(--emu-semantic-sizing-content-max-width);
      width: 100%;
  
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-inline-start: auto;
        margin-inline-end: auto;
        padding-block-end: var(--emu-component-cards-teaser-padding-bottom-wide);
        padding-inline-start: var(--emu-component-cards-teaser-padding-left-wide);
        padding-inline-end: var(--emu-component-cards-teaser-padding-right-wide);
        padding-block-start: var(--emu-component-cards-teaser-padding-top-wide);
      }
    }
  }

  .teaser-video-controls {
    display: none;
  }
}
