.emu-comparison-slider {
  margin-inline-start: -15px;
  margin-inline-end: -15px;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  &__slider {
    width: 100%;

    &__slot {
      .text {
        position: absolute;
        inset-block-start: 10px;

        .cmp-text {
          background-color: var(--emu-semantic-colors-secondary-grey-100);
          border-radius: 15px;
          padding-block-end: 2px;
          padding-inline-start: 15px;
          padding-inline-end: 15px;
          padding-block-start: 2px;

          p {
            color: var(--emu-semantic-colors-primary-darkblue);
            font-size: 14px;
            line-height: 20px;
            margin-block-start: 4px;
          }
        }
      }

      &--first {
        .text {
          inset-inline-start: 10px;
        }
      }

      &--second {
        .text {
          inset-inline-end: 10px;
        }
      }
    }
  }

  &__handle {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  .cmp-image__image {
    width: 100%;
  }

  img-comparison-slider {
    --handle-position-start: 90%;
  }
}