.c-brandstatements {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-block-end: 60px;
  max-width: var(--emu-semantic-sizing-content-max-width);
  margin-block-start: 0;
  margin-inline-end: auto;
  margin-inline-start: auto;
  overflow: hidden;

  @include mq($emu-semantic-sizing-breakpoints-large){
    justify-content: flex-start;
  }

  &.js-emu-inview {
    .title {
      animation-name: slideIn;
    }

    .text {
      animation-name: revealFromLeft;
    }

    .image {
      animation-name: revealFromBottom;
    }
  }

  .title,
  .text,
  .image {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  .title {
    width: 100%;
    word-break: break-word;
    margin-block-end: 20px;
    padding-block-start: 50px;
    padding-inline-end: 15px;
    padding-inline-start: 15px;
    padding-block-end: 0;
    z-index: var(--emu-common-other-z-index-layer);
    opacity: 0;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      width: 66%;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-inline-start: 0;
      padding-block-start: 120px;
    }

    .cmp-title {
      width: 66%;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: auto;
      }
    }
  }

  .text {
    order: 1;
    display: block;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    padding-block-end: 0;
    margin-inline-start: 16.667%;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      width: 50%;
      margin-block-start: 0;
      margin-inline-end: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      padding-inline-start: 15px;
      padding-inline-end: 15px;
      order: unset;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-inline-start: 0;
    }

    .text {
      margin-inline-start: 0;
      padding-inline-end: 0;
      padding-inline-start: 0;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: 100%;
        margin-block-start: 0;
        margin-inline-end: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
      }
      
    }

    p:not(:last-of-type) {
      margin-block-end: 20px;
    }
  }

  &::before {
    background-image: linear-gradient(230deg, var(--emu-semantic-colors-secondary-red-100) 0%, var(--emu-semantic-colors-secondary-blue-300) 100%);
    content: '';
    height: 360px;
    width: 115px;
    position: absolute;
    inset-inline-end: 0;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      height: 405px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      height: 620px;
      width: 34%;
    }
  }

  .image {
    flex: 0 0 100%;
    width: 100%;
    z-index: var(--emu-common-other-z-index-layer);
    inset-block-start: 0;
    padding-block-end: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    padding-inline-end: 15px;
    margin-block-end: 25px;
    position: relative;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      flex: 0 0 50%;
      padding-inline-start: 15px;
      order: 1;
      margin-block-end: 0;
    }

    .cmp-image__image {
      width: 100%;
    }
  }
}