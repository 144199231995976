.c-navigation {
  background-color: var(--emu-semantic-colors-primary-lightblue);
  padding-block-end: 30px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  padding-block-start: 10px;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    align-items: center;
    background-color: var(--emu-common-colors-transparent);
    flex-direction: row;
    padding-block-start: 0;
    padding-inline-end: 0;
    padding-block-end: 0;
    padding-inline-start: 0;
    position: relative;
    transform: none;
    transition: none;
  }

  &--mobile {
    .modal-wrapper {
      display: block;
      transform: translateX(100%);
      transition: transform 0.35s ease-in-out;

      &.is-open {
        transform: translateX(0);
      }
    }

    .modal-content {
      background-color: var(--emu-semantic-colors-primary-darkblue);
      height: 100%;
      margin-block-start: 0;
      margin-inline-end: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      max-width: none;
      padding-block-start: 0;
      padding-inline-end: 0;
      padding-block-end: 0;
      padding-inline-start: 0;
      width: 100%;

      .main-close {
        color: var(--emu-common-colors-white);
        font-weight: normal;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      > .container {
        height: 100%;

        > .aaaem-container {
          height: 100%;

          > .container {
            height: 100%;

            > .aaaem-container {
              height: 100%;
            }
          }
        }
      }
    }
  }
  
  &--desktop {
    display: none;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      display: flex;
    }
  }

  &-logo {
    margin-block-start: 5px;

    svg {
      display: block;
      height: 30px;
      width: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      display: none;
    }
  }

  &-wrapper-items.aaaem-container {
    background-color: var(--emu-common-colors-transparent);
    margin-block-start: 30px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      align-items: center;
      display: flex;
      gap: 30px;
      margin-inline-end: 30px;
      margin-block-start: 0;
    }
  }

  &-wrapper-top .emu-image__link {
    display: inline-block;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      display: block;
    }
  }

  &-wrapper-top,
  &-wrapper-bottom {
    background-color: var(--emu-semantic-colors-primary-lightblue);
    display: flex;
    
    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      background-color: initial;
      display: block;
    }
  }
  
  &-wrapper-top {
    justify-content: space-between;
  }

  &-wrapper-bottom {
    align-items: center;
    border-block-start: 1px solid var(--emu-common-colors-white);
    justify-content: space-between;
    padding-block-start: 40px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      background-color: var(--emu-common-colors-transparent);
      border-block-start: 0;
      display: flex;
      gap: 30px;
      padding-block-start: 0;
    }
  }

  &-findaclinic.aaaem-button {
    margin-block-start: -6px;
    padding-block-end: 0;
    padding-block-start: 6px;
  }

  .emu-navigation {
    &__content-wrapper {
      inset-inline-start: initial;
      position: relative;
      inset-block-start: initial;

      nav {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          background-color: var(--emu-common-colors-transparent);
        }
      }
    }

    &__item {
      a {
        border: 0;
        position: relative;
        text-transform: uppercase;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-inline-start: 0;
          padding-inline-start: 0;
          padding-inline-end: 0;
        }

        &:hover {
          background-color: var(--emu-common-colors-transparent);

          &::before {
            @include mq($emu-semantic-sizing-breakpoints-x-large) {
              width: 100%;
            }
          }
        }

        &::before {
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            background-color: var(--emu-common-colors-white);
            content: "";
            height: 1px;
            width: 0;
            transition: width .2s ease-in-out;
            position: absolute;
            inset-block-end: 0;
            inset-inline-start: 0;
          }
        }
      }
    }

    &__menu--level-0 {
      align-items: center;
      border-block-start: 1px solid var(--emu-common-colors-white);
      display: flex;
      flex-direction: column;
      padding-block-end: 20px;
      padding-block-start: 20px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        border-block-start: 0;
        flex-direction: row;
        padding-block-end: 0;
        padding-block-start: 0;
      }

      .emu-navigation__item:not(:first-of-type) {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-inline-start: 40px;
        }
      }
    }
  }

  .cmp-languagenavigation {
    position: relative;
    z-index: var(--emu-common-other-z-index-modal);

    &__switcher {
      color: var(--emu-common-colors-white);
      display: inline-flex;

      &.active {
        svg {
          transform: scaleY(-1);
        }
      }

      span {
        display: flex;
        margin-inline-start: 5px;
      }

      svg {
        display: inline-block;
        height: 20px;
        transform: scaleY(1);
        transition: all 0.2s ease-in-out;
        width: 20px;
      }
    }

    &__group {
      background-color: var(--emu-common-colors-white);
      display: flex;
      flex-direction: column;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      inset-inline-end: 0;
      inset-block-start: 0;
      transition: all 0.2s ease-in-out;
      transform: translateY(-100%);

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-block-start: 13px;
        inset-block-start: initial;
        transform: translateY(-20px);
      }

      &.active {
        opacity: 1;
        pointer-events: all;
        transform: translateY(calc(-100% - 12px));

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          transform: translateY(0);
        }
      }
    }

    &__item-link {
      color: var(--emu-semantic-colors-primary-darkblue);
      display: block;
      font-size: 16px;
      line-height: 25px;
      padding-block-end: 10px;
      padding-inline-start: 14px;
      padding-inline-end: 14px;
      padding-block-start: 10px;
      text-decoration: none;

      &:hover {
        background-color: var(--emu-common-colors-white);

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          background-color: var(--emu-common-colors-white);
          box-shadow: inset 3px 0 0 -1px var(--emu-semantic-colors-primary-darkblue);
        }
      }
    }
  }
}