.footer-info {
  background-color: var(--emu-semantic-colors-primary-darkblue);
  color: var(--emu-common-colors-white);
  margin-block-start: 60px;
  overflow: hidden;
  padding-block-end: 12px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  padding-block-start: 40px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    padding-block-end: 42px;
  }

  .footer-disclaimer,
  .footer-list-notes {
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
  }

  .cmp-text {
    a {
      color: var(--emu-common-colors-white);
    }
  }

  p,
  li {
    color: var(--emu-common-colors-white);
    font-size: 12px;
    line-height: 15px;
    opacity: 0.8;
    padding-block-end: 15px;
  }

  ol {
    margin-block-end: 0;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-block-start: 0;
    max-width: var(--emu-semantic-sizing-content-max-width);
    padding-inline-start: 20px;

    li {
      padding-inline-start: 15px;
    }
  }

  p {
    margin-block-end: 10px;
  }
}