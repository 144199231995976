.c-testimonialmedia {
  display: flex;
  flex-direction: column;
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-block-start: 40px;
  max-width: var(--emu-semantic-sizing-content-max-width);
  position: relative;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    flex-direction: row;
    justify-content: space-between;
    margin-block-end: 60px;
    margin-block-start: 60px;
  }

  &.js-emu-inview {
    .c-testimonialmedia__text {
      .cmp-text,
      blockquote {
        animation-name: slideIn;
      }
    }
  }

  &__text {
    background-color: var(--emu-semantic-colors-primary-lightblue);
    padding-block-end: 55px;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    padding-block-start: 40px;
    position: relative;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-inline-end: 98px;
      padding-block-end: 30px;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-inline-end: 190px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-inline-end: 0;
      padding-block-end: 20px;
      padding-inline-end: 130px;
      position: relative;
    }

    &::before {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        background-color: var(--emu-semantic-colors-primary-lightblue);
        content: "";
        height: 100%;
        inset-inline-start: 0;
        position: absolute;
        inset-block-start: 0;
        transform: translateX(-100%);
        width: 100vw;
      }
    }

    &::after {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        background-color: var(--emu-semantic-colors-primary-lightblue);
        inset-block-end: 0;
        content: "";
        position: absolute;
        inset-inline-end: 0;
        inset-block-start: 0;
        transform: translateX(100%);
        width: 550px;
  
        .c-testimonialmedia--image & {
          width: 37px;
        }
      }
    }

    > .title,
    > .text {
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-inline-start: -80px;
      }
    }

    .cmp-text,
    blockquote {
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    .cmp-text {
      margin-block-end: 30px;
      margin-inline-start: 65px;
      margin-block-start: 25px;
      
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-block-end: 0;
      }
      
      p {
        color: var(--emu-semantic-colors-text-light);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      blockquote {
        color: var(--emu-semantic-colors-text-light);
        font-family: var(--emu-semantic-font-families-heading);
        font-size: 41px;
        font-style: italic;
        line-height: 41px;
        position: relative;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          line-height: 45px;
        }
        
        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          width: 260px;
        }

        &::before {
          background-color: var(--emu-semantic-colors-primary-darkblue);
          content: "";
          display: block;
          height: 1px;
          inset-inline-start: 0;
          position: absolute;
          inset-block-start: 20px;
          transform: translateX(calc(-100% - 30px)); /* the before has to be placed outside of the container with a margin of 30px */
          width: 50px;
        }
      }
    }
  }

  &__media {
    background-color: var(--emu-common-colors-transparent);
    margin-inline-end: 15px;
    margin-block-start: -40px;
    position: relative;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-block-end: 40px;
      margin-inline-start: 176px;
      margin-inline-end: 50px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-block-end: 60px;
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin-block-start: 60px;
      
      .c-testimonialmedia--image & {
        margin-block-start: 0;
        padding-block-start: 60px;
      }

      &::before {
        .c-testimonialmedia--image & {
          background-color: var(--emu-semantic-colors-secondary-magenta-100);
          content: "";
          height: 60px;
          position: absolute;
          inset-block-start: 0;
          width: 73%;
        }
      }
    }

    > .image {
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-inline-start: auto;
        width: 725px;
      }
    }

    > .button {
      inset-block-end: 0;
      inset-inline-start: 0;
      position: absolute;
      inset-inline-end: 0;
      inset-block-start: 0;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-inline-start: auto;
        max-width: 725px;
      }

      .c-testimonialmedia__button {
        align-items: center;
        backdrop-filter: blur(10px);
        background-color: var(--emu-semantic-colors-transparent-white-60);
        border: 0;
        border-radius: 50%;
        inset-block-end: 30px;
        color: var(--emu-semantic-colors-primary-darkblue);
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding-block-end: 18px;
        padding-inline-start: 27px;
        padding-inline-end: 27px;
        padding-block-start: 18px;
        position: absolute;
        inset-inline-end: 30px;
        transition: background-color 0.2s ease;
        
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          padding-block-end: 49px;
          padding-inline-start: 56px;
          padding-inline-end: 56px;
          padding-block-start: 49px;
        }

        &:hover {
          &::before {
            border-inline-start-color: var(--emu-semantic-colors-primary-lightblue);
          }
        }

        &::before {
          border: 11px solid var(--emu-common-colors-transparent);
          border-left: 16px solid var(--emu-semantic-colors-primary-darkblue);
          border-right: none;
          content: "";
          display: block;
          transform: translate(20%, 5%);
          width: 0;

          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            border-bottom-width: 22px;
            border-left-width: 30px;
            border-top-width: 22px;
          }
        }
      }

      .cmp-button__text {
        font-size: 0;
      }
    }

    .cmp-image__image {
      max-height: 55vw;
      object-fit: cover;
      width: 100%;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        max-height: 40vw;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        max-height: 408px;
      }
    }
  }

  &__paragraph {
    &--mobile {
      padding-block-end: 40px;
      padding-inline-start: 15px;
      padding-inline-end: 15px;
      padding-block-start: 40px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        padding-block-start: 0;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        display: block;
        padding-inline-end: 15px;
        padding-block-start: 20px;
      }
    }
  }

  &__modal {
    .modal-wrapper {
      background-color: var(--emu-semantic-colors-transparent-black-600);
    }

    .modal-content {
      margin-block-end: auto;
      margin-inline-start: auto;
      margin-inline-end: auto;
      margin-block-start: 50%;
      max-width: 830px;
      padding-block-end: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
      padding-block-start: 0;
      width: calc(100vw - 30px); /* the modal takes the full width minus a margin of 15px on each side */

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-block-start: 25%;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-block-start: 8%;
        max-width: var(--emu-semantic-sizing-modal-max-width);
      }

      .main-close {
        align-items: center;
        display: flex;
        height: 40px;
        justify-content: center;
        margin-block-start: -60px;
        width: 40px;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-inline-end: -60px;
          margin-block-start: 0;
        }

        &:hover {
          opacity: 1;

          span {
            transform: rotate(90deg);
          }
        }

        span {
          color: var(--emu-semantic-colors-secondary-grey-100);
          font-size: 70px;
          font-weight: 300;
          transition: transform 0.2s ease;
        }
      }
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .c-testimonialmedia {
    &__text {
      .cmp-text {
        blockquote {
          font-family: var(--emu-semantic-font-families-heading-assistant);
        }
      }
    }
  }
}