.c-bulletpoints {
  margin-block-end: 40px;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 40px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-block-end: 60px;
  }

  .tns-outer {
    display: flex;
    justify-content: center;
  }

  .tns-inner {
    direction: ltr;
    max-width: var(--emu-semantic-sizing-content-max-width);

    .cmp-text {
      padding-block-end: 20px;
      padding-inline-start: 15px;
      padding-inline-end: 15px;

      h3 {
        padding-block-end: 30px;
      }
    }
  }
}