.c-search-location {
  padding-block-end: 69px;
  padding-block-start: 100px;
  width: 100%;

  &.js-emu-inview {
    .c-search-area {
      animation-name: slideIn;
    }
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    padding-block-end: 120px;
    padding-block-start: 100px;
  }

  &__countries {
    background-color: var(--emu-semantic-colors-secondary-blue-400);
    color: var(--emu-semantic-colors-primary-blue-300);
    margin-inline-end: auto;
    margin-inline-start: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
    padding-inline-end: 15px;
    padding-inline-start: 15px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-inline-end: 0;
      padding-inline-start: 0;
    }
  }

  .cmp-title__text {
    margin-block-end: 30px;
  }

  .c-search-area {
    animation-delay: 0.3s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    display: flex;
    flex-wrap: wrap;
    margin-inline-end: auto;
    margin-inline-start: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);
    opacity: 0;
    padding-inline-end: 15px;
    padding-inline-start: 15px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      flex-wrap: nowrap;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-inline-end: 0;
      padding-inline-start: 0;
    }

    &.hidden {
      display: none;

      @include aem-editor-view() {
        display: block;
        opacity: 1;
      }
    }

    > .container {
      width: 100%;

      &:first-child {
        margin-block-end: 50px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-block-end: 0;
        }
      }

      &:last-child {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-inline-start: 8%;
          max-width: 42%;
        }
      }
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    margin-block-start: 0;
  }

  .emu-location-services {
    &__form {
      flex-direction: column;
    }

    &__location-input {
      background-color: var(--emu-semantic-colors-secondary-blue-600);
      // Magnifier/search icon
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg stroke='%2300A3E0'%3E%3Cg%3E%3Cg transform='translate(-525.000000, -263.000000) translate(525.000000, 263.000000) translate(3.000000, 3.000000)'%3E%3Ccircle cx='5.5' cy='5.5' r='5'/%3E%3Cpath d='M13 13L9 9'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      border-block-end-color: var(--emu-semantic-colors-primary-lightblue);
      border-block-end-width: 1px;
      color: var(--emu-semantic-colors-text-light);
      padding-block-start: 6px;

      &::placeholder {
        color: var(--emu-semantic-colors-text-light);
      }
    }

    &__search-submit-cta {
      align-self: flex-end;
      margin-block-start: 20px;
    }

    &__country-select-wrapper {
      display: flex;
      flex-direction: column;
      margin-block-end: 60px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
      }

      label {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        margin-block-end: 30px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          flex: 0 0 50%;
          margin-block-end: 0;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          font-size: 40px;
          line-height: 48px;
        }
      }

      select {
        background-color: var(--emu-semantic-colors-transparent-white-300);
        border: 0;
        border-bottom: 1px solid var(--emu-semantic-colors-primary-blue-300);
        color: var(--emu-semantic-colors-primary-blue-300);
        font-size: 18px;
        line-height: 32px;
        height: 50px;
        padding-inline-start: 10px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          flex: 0 0 42%;
        }
      }
    }
  }

  .c-search-location__input-container.js-has-location-null-error {
    .emu-location-services__location-input {
      border: 2px solid var(--emu-semantic-colors-error);
    }

    .emu-location-services__location-input-error {
      display: block;
    }
  }

  &__btn-container {
    display: flex;
    flex-wrap: wrap;

    .button {
      display: block;
      margin-block-end: 20px;
      margin-inline-end: 15px;
      transition: 0.3s;
      width: calc(50% - 15px);

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        width: calc(33.3% - 15px);
      }

      &.hidden {
        display: none;

        @include aem-editor-view() {
          display: block;
        }
      }

      .aaaem-button {
        display: block;
        padding-block-end: 2px;
        padding-block-start: 6px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          max-width: 250px;
        }

        > span {
          display: block;
          overflow: hidden;
          text-align: center;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
