.clinic-popup {
  &__heading {
    color: var(--emu-semantic-colors-primary-lightblue);
    font-family: var(--emu-semantic-font-families-body);
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin-block-end: 7px;
  }

  &__description {
    color: var(--emu-semantic-colors-text-light);
    font-family: var(--emu-semantic-font-families-body);
    font-size: 14px;
    line-height: 20px;
    margin-block-end: 20px;
  }

  &__buttons-container {
    display: flex;
  }

  &__link {
    color: var(--emu-common-colors-white);
    cursor: pointer;
    display: inline-block;
    font-family: var(--emu-semantic-font-families-body);
    font-size: 18px;
    height: 40px;
    line-height: 32px;
    min-width: 40px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:visited {
      color: var(--emu-common-colors-white);
    }

    &-website,
    &-phone {
      background-color: var(--emu-component-actions-button-color-background-primary-filled-dark);
      border-radius: 25px;
      margin-inline-end: 10px;
      padding-block-end: 2px;
      padding-block-start: 6px;
      padding-inline-end: 20px;
      padding-inline-start: 20px;

      &:active {
        background-color: var(--emu-component-actions-button-color-background-primary-filled-active-dark);
        color: var(--emu-component-actions-button-text-color-primary-filled-active-dark);
      }

      &:hover {
        background: linear-gradient(to right, var(--emu-semantic-colors-secondary-blue-200), var(--emu-semantic-colors-primary-lightblue));
        color: var(--emu-component-actions-button-text-color-primary-filled-active-dark);
      }
    }

    &-clinic {
      border: 1px solid var(--emu-semantic-colors-primary-lightblue);
      border-radius: 50%;
      margin-inline-start: auto;
      padding-block-end: 8px;
      padding-block-start: 8px;
      padding-inline-end: 8px;
      padding-inline-start: 8px;

      &:active {
        border-color: var(--emu-component-actions-button-color-background-primary-filled-active-dark);
      }

      &:hover {
        border-color: var(--emu-semantic-colors-primary-lightblue);
        color: var(--emu-common-colors-white);
      }
    }

    &-icon {
      inset-block-start: 50%;
      inset-inline-start: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
}

// Importants are required to overwite Google styles
.gm-style {
  .gm-style-iw-c {
    box-shadow: 0 1px 4px 0 var(--emu-semantic-colors-secondary-grey-200);
    max-width: 340px !important;
    padding-inline-end: 16px;
    padding-inline-start: 16px;
    padding-left: unset !important;
    padding-right: unset !important;

    > button {
      inset-inline-end: 7px !important;
      inset-block-start: 2px !important;
    }
  }

  .gm-style-iw-d {
    padding-inline-start: 16px;
  }

  .gm-ui-hover-effect {
    top: unset !important;
    right: unset !important;
    inset-block-start: -6px !important;
    inset-inline-end: -6px !important;
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .clinic-popup {
    &__heading {
      font-family: var(--emu-semantic-font-families-body-assistant);
    }
    &__description {
      font-family: var(--emu-semantic-font-families-body-assistant);
    }
    &__link {
      font-family: var(--emu-semantic-font-families-body-assistant);
    }
  }
}