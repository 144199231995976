.c-ba-carousel {
  margin-block-start: 60px;
  position: relative;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    margin-block-start: 215px;
  }
  
  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-block-end: 80px;
  }

  &.js-emu-inview {
    > .container > .aaaem-container {
      animation-name: slideIn;
    }
  }

  > .container {
    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      background-color: var(--emu-semantic-colors-secondary-blue-500);
    }

    > .aaaem-container {
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      margin-inline-start: auto;
      margin-inline-end: auto;
      max-width: var(--emu-semantic-sizing-content-max-width);
      opacity: 0;
    }

    .aaaem-container {
      background-color: var(--emu-common-colors-transparent);
      max-width: var(--emu-semantic-sizing-content-max-width);
    }
  }

  .cmp-title {
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);

    &__text {
      margin-block-end: 30px;
      margin-inline-start: 15px;
      margin-inline-end: 15px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-block-end: -140px;
      }

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        margin-inline-start: 0;
        margin-inline-end: 0;
      }
    }
  }

  .aaaem-carousel {
    display: flex;
    flex-direction: column;
    padding-inline-start: 15px;
    padding-inline-end: 15px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      flex-direction: row;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }

    &__indicators {
      display: flex !important; /* to overwrite the display: none added with JS by tiny slider when there is only one slide */
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-block-end: 30px;
      margin-block-start: 30px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        align-content: flex-start;
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        margin-block-start: 160px;
        padding-inline-end: 30px;
      }
    }

    &__indicator {
      border: 0;
      border-radius: 0;
      display: flex;
      height: 2px;
      position: relative;
      width: 80px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-block-start: 30px;
      }

      &.tns-nav-active {
        > span:first-of-type {
          color: var(--emu-semantic-colors-primary-lightblue);
          font-weight: 500;
        }
      }

      &.show-progress {
        overflow: visible;

        span {
          height: 2px;
        }
      }

      > span:first-of-type {
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-semantic-colors-primary-darkblue);
        font-size: 14px;
        height: auto;
        padding-block-end: 5px;
        position: absolute;
        text-indent: 0;
        inset-block-start: -25px;
        width: 100%;
      }
    }

    &__item {
      font-size: 0;
    }

    .tns-outer {
      order: 2;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        display: flex;
        margin-block-start: -60px;
      }
    }

    .tns-inner {
      direction: ltr;
    }

    .cmp-image__image {
      width: 100%;
    }
  }
}