.p-sitemap {
  header.header {
    background-color: var(--emu-semantic-colors-primary-lightblue);
  }

  .p-sitemap-container {
    margin-block-end: 105px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-block-start: 120px;
    max-width: var(--emu-semantic-sizing-content-max-width);
    padding-inline-start: 15px;
    padding-inline-end: 15px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-block-start: 150px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }

    a {
      color: var(--emu-semantic-colors-text-light);
    }
  }
}