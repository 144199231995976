body {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-semantic-font-weight-regular);
}

h1,
h2,
h3,
h4 {
  font-family: var(--emu-semantic-font-families-heading);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
  color: var(--emu-common-colors-white);

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }

  sup {
    font-size: 35px;
  }
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }

  sup {
    font-size: 24px;
    line-height: 0;
  }
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
  }
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
  font-family: var(--emu-semantic-font-families-body);
  font-weight: 500;
  color: var(--emu-semantic-colors-primary-lightblue);

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    font: var(--emu-component-ingredients-title-h5-typography-wide);
  }
}

p {
  font: var(--emu-component-ingredients-text-text-typography-narrow);

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    font: var(--emu-component-ingredients-text-text-typography-wide);
  }

  sup {
    font-size: 12px;
    line-height: 0;
  }
}

small {
  font-size: 12px;
  line-height: 15px;

  sup {
    font-size: 10px;
  }
}
// Country overrides
html[lang='he'],
html[lang='he_il'] {
  body {
    font-family: var(--emu-semantic-font-families-body-assistant);
  }
  h1,
  h2,
  h3,
  h4 {
    font-family: var(--emu-semantic-font-families-heading-assistant);
  }
  h1 {
    font: var(--emu-component-ingredients-title-h1-typography-narrow-assistant);

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      font: var(--emu-component-ingredients-title-h1-typography-wide-assistant);
    }
  }

  h2 {
    font: var(--emu-component-ingredients-title-h2-typography-narrow-assistant);

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      font: var(--emu-component-ingredients-title-h2-typography-wide-assistant);
    }
  }

  h3 {
    font: var(--emu-component-ingredients-title-h3-typography-narrow-assistant);

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      font: var(--emu-component-ingredients-title-h3-typography-wide-assistant);
    }
  }

  h4 {
    font: var(--emu-component-ingredients-title-h4-typography-narrow-assistant);

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      font: var(--emu-component-ingredients-title-h4-typography-wide-assistant);
    }
  }

  h5 {
    font: var(--emu-component-ingredients-title-h5-typography-narrow-assistant);
    font-family: var(--emu-semantic-font-families-body-assistant);

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      font: var(--emu-component-ingredients-title-h5-typography-wide-assistant);
    }
  }

  p {
    font: var(
      --emu-component-ingredients-text-text-typography-narrow-assistant
    );

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      font: var(
        --emu-component-ingredients-text-text-typography-wide-assistant
      );
    }
  }
}
