.c-beforeandaftershort {
  margin-block-end: 40px;
  margin-block-start: 40px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-block-start: 60px;
  }

  &__title {
    &--mobile {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        display: none;

        @include aem-editor-view() {
          display: block;
        }
      }
    }

    &--desktop {
      display: none;

      @include aem-editor-view() {
        display: block;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        display: block;
      }
    }
  }

  &__container {
    > .container {
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        background-color: var(--emu-semantic-colors-secondary-blue-600);
      }
      
      > .cmp-container {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          background-color: var(--emu-semantic-colors-secondary-blue-600);
          display: flex;
          margin-inline-start: auto;
          margin-inline-end: auto;
          max-width: var(--emu-semantic-sizing-content-max-width);
          padding-inline-start: 15px;
          padding-inline-end: 15px;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          padding-inline-start: 0;
          padding-inline-end: 0;
        }

        > .comparisonslider,
        > .title {
          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            width: 50%;
          }
        }

        > .title {
          .cmp-title {
            @include mq($emu-semantic-sizing-breakpoints-x-large) {
              padding-inline-start: 100px;
              padding-block-start: 70px;
            }
          }
        }
      }
    }
  }

  .cmp-title {
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }

    &__text {
      margin-block-end: 30px;
      
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-block-end: 90px;
      }
    }
    
    h2 {
      margin-block-start: 60px;
    }
  }

  .emu-comparison-slider {
    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-block-end: -10px;
      margin-block-start: -60px;
    }
  }
}