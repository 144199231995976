.p-qa {
 .p-qa-container {
    margin-block-end: 60px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-block-end: 80px;
    }
  }

  .cmp-teaser {
    margin-block-end: 0;
  }

  .c-introtext {
    margin-block-end: 30px;
  }
}