.c-footnotes {
  margin-inline-start: 15px;
  margin-inline-end: 15px;
  max-width: var(--emu-semantic-sizing-content-max-width);
  
  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  p {
    font-size: 12px;
    line-height: 15px;
    
    &:not(:last-of-type) {
      margin-block-end: 15px;
    }

    sup {
      font-size: 8px;
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .c-footnotes {
    p {
      font-size: 12px;
      line-height: 15px;
      
      &:not(:last-of-type) {
        margin-block-end: 15px;
      }
  
      sup {
        font-size: 8px;
      }
    }
  }
}