.emu-location-services {
  &__form {
    display: flex;
    flex-wrap: wrap;
    margin-block-end: 20px;
  }

  &__country-select-wrapper {
    flex: 3 1 100%;
    margin-inline-end: 8px;

    select {
      background-color: var(--emu-semantic-colors-transparent-white-100);
      border: none;
      border-block-end-color: var(--emu-semantic-colors-transparent-blue-200);
      border-block-end-style: solid;
      border-block-end-width: 2px;
      border-radius: 0;
      color: var(--emu-semantic-colors-primary-blue-300);
      font-size: 18px;
      line-height: 20px;
      padding-inline-start: 10px;

      &:invalid {
        color: var(--emu-semantic-colors-transparent-primary-blue-300-a60);
      }
    }
  }

  &__location-input-wrapper {
    flex: 3 1 auto;
    margin-block-end: 0;
    
    .emu-location-services__location-input {
      // Styles for the search icon
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg stroke='%2300A3E0'%3E%3Cg%3E%3Cg transform='translate(-525.000000, -263.000000) translate(525.000000, 263.000000) translate(3.000000, 3.000000)'%3E%3Ccircle cx='5.5' cy='5.5' r='5'/%3E%3Cpath d='M13 13L9 9'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: left 10px center;
      background-repeat: no-repeat;
      background-size: 20px;
      padding-block-start: 6px;
      padding-inline-start: 40px;
    
      // Styles for the input field
      background-color: var(--emu-semantic-colors-secondary-blue-600);
      border: none;
      border-block-end: solid 2px var(--emu-semantic-colors-primary-lightblue);
      border-radius: 0;
      color: var(--emu-semantic-colors-text-light);
      font-size: 18px;
      line-height: 20px;
    
      &::placeholder {
        color: var(--emu-semantic-colors-text-light);
        opacity: 0.6;
      }
    
      ~ .emu-location-services__location-input-error,
      ~ .emu-location-services__location-null-error {
        color: var(--emu-semantic-colors-secondary-red-100);
        display: none;
        font-size: 14px;
        padding-block-end: 0;
        padding-block-start: 2px;
        padding-inline-end: 0;
        padding-inline-start: 2px;
      }
    }
  }

  &__location-input,
  &__search-submit-cta,
  &__country-select-wrapper select {
    height: 40px;
  }

  &__search-submit-cta {
    background-color: var(--emu-component-actions-button-color-background-primary-filled-dark);
    border-radius: 25px;
    color: var(--emu-component-actions-button-text-color-primary-filled-dark);
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 18px;
    line-height: 32px;
    margin-inline-start: 20px;
    padding-block-end: 2px;
    padding-block-start: 6px;
    padding-inline-end: 20px;
    padding-inline-start: 20px;
    position: relative;
    text-decoration: none;

    &:active {
      background-color: var(--emu-component-actions-button-color-background-primary-filled-active-dark);
      outline-color: var(--emu-component-actions-button-color-outline-color-primary-filled-active-dark);
    }

    &:focus {
      box-shadow: 0 0 4px 4px var(--emu-semantic-colors-secondary-blue-200);
    }
  
    &:hover {
      background: linear-gradient(to right, var(--emu-semantic-colors-secondary-blue-200), var(--emu-semantic-colors-primary-lightblue));
    }
  }

  &__current-location-btn {
    border: none;
    color: var(--emu-semantic-colors-text-light);
    cursor: pointer;
    max-width: 100%;
    padding: 0;
    text-align: left;
    text-decoration: underline;
  
    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      text-align: center;
    }
  }

  &__location-input-wrapper,
  &__search-submit-cta,
  &__current-location-btn {
    &.hide {
      display: none;
    }
  }
}

.c-find-the-clinic-video__search.js-has-location-null-error {
  .emu-location-services__location-input {
    border: 2px solid var(--emu-semantic-colors-secondary-red-100);
  }

  .emu-location-services__location-input-error {
    display: block;
  }
}

#emu-location-services__location-input-error {
  display: none;
}

// Class names come from external google service
.pac-container {
  border-block-start: none;
  padding-block-end: 20px;

  .pac-item {
    border: none;
    font-size: 14px;
    line-height: 20px;
    padding-block-end: 0;
    padding-block-start: 20px;
    padding-inline-end: 44px;
    padding-inline-start: 44px;
  }

  // .pac-logo:after hiding google disclamer
  &.pac-logo:after,
  .pac-icon {
    display: none;
  }
}