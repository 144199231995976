.aaaem-button {
  &__primary-filled {
    &:hover {
      background: linear-gradient(to right, var(--emu-semantic-colors-secondary-blue-200), var(--emu-semantic-colors-primary-lightblue));
    }

    &:focus {
      box-shadow: 0 0 4px 4px var(--emu-semantic-colors-secondary-blue-200);
    }

    &:visited {
      color: var(--emu-common-colors-white);
    } 
  }
}