@font-face {
  font-family: 'Founders-Grotesk';
  font-display: swap;
  font-weight: 300;
  src: url('./assets/fonts/FoundersGrotesk/FoundersGrotesk-Light.woff2') format('woff2'),
    url('./assets/fonts/FoundersGrotesk/FoundersGrotesk-Light.woff') format('woff');
}

@font-face {
  font-family: 'Founders-Grotesk';
  font-display: swap;
  font-weight: 300;
  font-style: italic;
  src: url('./assets/fonts/FoundersGrotesk/FoundersGrotesk-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/FoundersGrotesk/FoundersGrotesk-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Founders-Grotesk';
  font-display: swap;
  font-weight: 500;
  src: url('./assets/fonts/FoundersGrotesk/FoundersGrotesk-Medium.woff2') format('woff2'),
    url('./assets/fonts/FoundersGrotesk/FoundersGrotesk-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Founders-Grotesk';
  font-display: swap;
  font-weight: 500;
  font-style: italic;
  src: url('./assets/fonts/FoundersGrotesk/FoundersGrotesk-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/FoundersGrotesk/FoundersGrotesk-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Saol-Standard';
  font-display: swap;
  font-weight: 300;
  src: url('./assets/fonts/SaolStandard/SaolStandard-Light.woff2') format('woff2'),
    url('./assets/fonts/SaolStandard/SaolStandard-Light.woff') format('woff');
}

@font-face {
  font-family: 'Saol-Standard';
  font-display: swap;
  font-weight: 300;
  font-style: italic;
  src: url('./assets/fonts/SaolStandard/SaolStandard-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/SaolStandard/SaolStandard-LightItalic.woff') format('woff');
}