footer {
  background-color: var(--emu-semantic-colors-primary-darkblue);
  margin-block-end: 0;
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  padding-block-start: 0;
  position: relative;

  &::before {
    aspect-ratio: 130 / 203;
    background-image: url("../../assets/images/footer-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: "";
    display: block;
    height: 100%;
    overflow: hidden;
    position: absolute;
    inset-inline-end: 0;
  }

  .c-footer {
    background-color: var(--emu-semantic-colors-primary-darkblue);
    margin-block-end: 0;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-block-start: 0;
    padding-block-end: 40px;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-block-start: 40px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      max-width: var(--emu-semantic-sizing-content-max-width);
    }

    .text {
      padding-block-end: 0;
      padding-inline-start: 15px;
      padding-inline-end: 15px;
      padding-block-start: 0;

      .text {
        padding-block-end: 0;
        padding-inline-start: 0;
        padding-inline-end: 0;
        padding-block-start: 0;
      }

      .cmp-text {
        &.aaaem-text {
          p {
            color: var(--emu-common-colors-white);
          }

          ol {
            list-style-position: inside;
            padding-block-end: 0;
            padding-inline-start: 0;
            padding-inline-end: 0;
            padding-block-start: 0;
          }
        }
      }

      &:last-child {
        p {
          font-size: 12px;
          line-height: 15px;
          margin-block-end: 20px;
          margin-inline-start: 0;
          margin-inline-end: 0;
          margin-block-start: 20px;
          opacity: 0.8;

          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            font-size: 14px;
          }

        }
      }
    }

    .container {
      .cmp-container {
        align-items: center;
        background-color: var(--emu-semantic-colors-primary-darkblue);
        display: flex;
        justify-content: end;
        padding-block-end: 30px;
        padding-inline-start: 0;
        padding-inline-end: 0;
        padding-block-start: 0;

        .button {
          .aaaem-button {
            &.aaaem-button__primary-filled {
              background-color: var(--emu-common-colors-transparent);
              margin-inline-start: 30px;
              padding-block-end: 0;
              padding-inline-start: 0;
              padding-inline-end: 0;
              padding-block-start: 0;

              &:active {
                outline: none;
              }

              &:active,
              &:hover {
                background: none;
                color: var(--emu-common-colors-white);

                & svg {
                  fill: var(--emu-common-colors-white);
                }
              }

              svg {
                height: 30px;
                margin-inline-end: 0;
                width: 30px;
              }
            }
          }

          &:nth-last-child(3) {
            flex-grow: 2;
          }
          &:first-child {
            flex-grow: 2;
          }
        }
        &.no-icl{
          .button {
            &:first-child {
              flex-grow: unset;
            }
          }
        }
      }

      .aaaem-container__position-static {
        padding-block-end: 0;

        .aem-Grid {
          border-block-start: 1px solid var(--emu-semantic-colors-transparent-white-50);

          .aem-GridColumn.aem-GridColumn--default--12:first-child {
            @include mq($emu-semantic-sizing-breakpoints-large) {
              float: right;
              width: 33%;
            }
          }

          .aem-GridColumn.aem-GridColumn--default--12:nth-child(2) {
            @include mq($emu-semantic-sizing-breakpoints-large) {
              width: 66%;
            }
          }
        }

        .c-footer-logos {
          align-items: center;
          flex-direction: column;
          margin-block-start: 30px;

          @include mq($emu-semantic-sizing-breakpoints-large) {
            align-items: end;
          }

          .image {
            margin-block-end: 40px;

            .cmp-image__link {
              display: block;
            }

            svg {
              scale: 1.4;

              @include mq($emu-semantic-sizing-breakpoints-large) {
                margin-inline-end: 20px;
              }
            }

            + .image {
              margin-block-end: 20px;

              @include mq($emu-semantic-sizing-breakpoints-large) {
                margin-block-end: 30px;
              }

              svg {
                scale: 1;

                @include mq($emu-semantic-sizing-breakpoints-large) {
                  margin-inline-end: 0;
                }
              }
            }
          }

          > .container {
            .cmp-container {
              align-items: center;
              padding-block-end: 0;

              .cmp-text {
                p {
                  margin-block-start: 30px;
                }
              }

              .image {
                flex-shrink: 0;
                margin-block-end: 0;
              }
            }

            .cmp-image__image {
              height: 30px;
            }
          }
        }
      }
    }

    .container:nth-child(2) {
      > .cmp-container > .aem-Grid {
        .navigationlist:nth-child(3) {
          border-block-start: 1px solid var(--emu-semantic-colors-transparent-white-35);
          margin-inline-start: 0;
          margin-inline-end: 0;
          margin-block-start: 0;
          padding-inline-start: 0;
          padding-inline-end: 0;
          padding-block-start: 40px;
          position: relative;
          z-index: var(--emu-common-other-z-index-layer);

          @include mq($emu-semantic-sizing-breakpoints-large) {
            position: relative;
            text-align: center;
          }

          .emu-navigation {
            background-color: var(--emu-common-colors-transparent);
            padding-block-end: 0;
            padding-inline-start: 0;
            padding-inline-end: 0;
            padding-block-start: 0;
          }

          .emu-navigation__menu--level-0 {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-block-end: 0;
            padding-block-start: 0;

            @include mq($emu-semantic-sizing-breakpoints-large) {
              justify-content: center;
            }

            li:nth-child(2) {
              text-align: left;
              width: 80%;

              @include mq($emu-semantic-sizing-breakpoints-large) {
                width: auto;
              }
            }

            .emu-navigation__item {
              display: inline-block;
              padding-block-end: 15px;
              padding-inline-end: 15px;
              padding-block-start: 0;

              @include mq($emu-semantic-sizing-breakpoints-large) {
                margin-inline-end: 0;
              }

              .nav-item {
                border: 0;
                display: inline-block;
                padding-block-end: 0;
                padding-inline-start: 0;
                padding-inline-end: 0;
                padding-block-start: 0;
            
                &:hover {
                  background-color: var(--emu-common-colors-transparent);
                }

                a {
                  border-block-start: 0;
                  color: var(--emu-common-colors-white);
                  font-size: 12px;
                  line-height: 15px;
                  margin-inline-start: 0;
                  margin-inline-end: 0;
                  padding-block-end: 0;
                  padding-inline-start: 0;
                  padding-inline-end: 0;
                  padding-block-start: 0;
                  text-transform: none;
  
                  &:hover,
                  &:active {
                    text-decoration: underline;
                    color: var(--emu-common-colors-white);
                  }

                  &::before {
                    display: none;
                  }
                }
              }
            }
          }

          & + .text {
            padding-block-end: 40px;
            padding-inline-start: 0;
            padding-inline-end: 0;
            padding-block-start: 40px;

            @include mq($emu-semantic-sizing-breakpoints-large) {
              position: relative;
            }

          }

          & ~ .image {
            inset-block-end: 0;
            display: flex;
            height: 377px;
            justify-content: center;
            overflow: hidden;
            position: absolute;
            inset-inline-end: 0;
            width: 100%;

            .p-home &,
            .p-results &,
            .p-qa &,
            .p-about & {
              inset-block-end: -97px; /* height of the container below */

              @include mq($emu-semantic-sizing-breakpoints-xx-large) {
                inset-block-end: -132px;
              }
            }

            .emu-image {
              height: 100%;
              width: 100%;

              svg {
                inset-block-end: -8px;
                height: 100%;
                min-height: 377px;
                overflow: hidden;
                position: absolute;
                inset-inline-end: 0;
                width: 100%;

                @include mq($emu-semantic-sizing-breakpoints-large) {
                  height: 377px;
                  width: 373px;
                }
              }

              g {
                scale: 1.3;

                @include mq($emu-semantic-sizing-breakpoints-large) {
                  scale: 1.2;
                }
              }
            }
          }
        }
      }
    }

    .navigationlist {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }

  .emu-navigation {
    &__content-wrapper {
      position: relative;

      .emu-navigation {
        background-color: var(--emu-common-colors-transparent);
        z-index: var(--emu-common-other-z-index-layer);
      }

      .emu-navigation__menu--level-0 {
        background-color: var(--emu-common-colors-transparent);
        padding-block-end: 40px;
        padding-block-start: 30px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          border-block-start: 0;
        }

        .emu-navigation__item {
          &:not(:last-of-type) {
            padding-block-end: 10px;

            @include mq($emu-semantic-sizing-breakpoints-large) {
              margin-inline-end: 106px;
              padding-block-end: 0;
            }
          }

          a {
            border-block-end: 0;
            border-inline-start: 0;
            border-inline-end: 0;
            border-block-start: 1px solid var(--emu-semantic-colors-transparent-white-10);
            line-height: 32px;
            padding-block-end: 10px;
            padding-inline-start: 0;
            padding-inline-end: 0;
            padding-block-start: 10px;
            position: relative;
            text-transform: uppercase;

            @include mq($emu-semantic-sizing-breakpoints-large) {
              border-block-start: 0;
            }

            &::before {
              @include mq($emu-semantic-sizing-breakpoints-large) {
                background-color: var(--emu-common-colors-white);
                inset-block-end: 0;
                content: "";
                height: 1px;
                inset-inline-start: 0;
                position: absolute;
                transition: width .2s ease-in-out;
                width: 0;
              }
            }

            &:hover {
              background-color: var(--emu-common-colors-transparent);

              &::before {
                @include mq($emu-semantic-sizing-breakpoints-large) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
