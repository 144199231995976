.c-introtext {
  margin-block-end: 20px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-block-start: 60px;
  max-width: var(--emu-semantic-sizing-content-max-width);
  padding-inline-start: 15px;
  padding-inline-end: 15px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  .cmp-title__text {
    margin-block-end: 30px;
  }

  p:not(:last-of-type) {
    margin-block-end: 15px;
  }

  ul,
  ol {
    padding-inline-start: 30px;
  }

  li {
    color: var(--emu-semantic-colors-text-light);
    font-size: 16px;
    line-height: 30px;
    padding-inline-start: 15px;

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      font-size: 22px;
    }

    &:not(:last-of-type) {
      margin-block-end: 15px;
    }
  }
}