@keyframes slideIn {
  0% {
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes revealFromLeft {
  0% {
    clip-path: polygon(0 0, 0 0, 0 0, 0 50%);
    opacity: 0;
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    opacity: 1;
  }
}

@keyframes revealFromBottom {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    opacity: 0;
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    opacity: 1;
  }
}

@keyframes moveDown {
  0% {
    inset-block-start: -200px;
  }

  to {
    inset-block-start: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}