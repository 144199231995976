.c-qacontainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  margin-block-end: 20px; 
  max-width: var(--emu-semantic-sizing-content-max-width);
  margin-block-start: 45px;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  .title {
    width: var(--emu-semantic-sizing-content-max-width);

    h2 {
      margin-block-end: 40px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-block-end: 30px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-block-end: 60px;
        max-width: 66.6%;
      }
    }

    h3 {
      margin-block-end: 40px;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-block-end: 30px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-block-end: 60px;
      }
    }
  }

  &.js-emu-inview {
    > .text{
      animation-name: slideIn;
    }

    .emu-image {
      animation-name: revealFromBottom;
    }
  }

  p {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-inline-start: auto;
    }
  }

  li {
    color: var(--emu-semantic-colors-text-light);
  }

  &.text-isolated {
    .text {
      width: 100%;

      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: 85%;
        margin-inline-start: auto;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        width: 70%;
      }

      ol {
        padding-inline-start: 0;
        list-style-position: inside;
      }
    }
  }

  > .text {
    order: 4;
    width: 100%;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      width: 75%;
      margin-inline-start: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      width: 50%;
    }

    p { 
      @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-inline-start: auto;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-block-start: 40px;
      }
    }
  }
  
  &.text-highlighted {
    > .text {
      width: 100%;
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      color: var(--emu-semantic-colors-text-light);
      order: 1;
      opacity: 0;
      margin-inline-start: unset;
  
      @include mq($emu-semantic-sizing-breakpoints-large) {
        width: 59%;
      }
  
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        width: 45%;
      }
  
      h5 {
        margin-block-end: 27px;
        margin-inline-start: 0;
        padding-block-start: 20px;
        padding-inline-end: 20px;
        padding-block-end: 40px;
        padding-inline-start: 15px;
        position: relative;
        max-width: 100%;
        background-color: var(--emu-semantic-colors-secondary-blue-400);
        font-family: var(--emu-semantic-font-families-body);
  
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-block-end: 60px;
          padding-block-start: 40px;
          padding-inline-end: 40px;
          padding-block-end: 60px;
          padding-inline-start: 0;
        }
  
        sup {
          font-size: 10px;
        }
  
        &::before {
          content: "";
          background-color: var(--emu-semantic-colors-secondary-blue-400);
          inset-block-end: 0;
          display: block;
          inset-inline-start: 1px;
          position: absolute;
          inset-block-start: 0;
          transform: translateX(-100%);
          width: 50vw;
          z-index: var(--emu-common-other-z-index-layer);
        }
      }
    
      + .text {
        margin-inline-start: auto;
      }
    }

    > div:nth-child(2) {
      order: initial;
  
      @include mq($emu-semantic-sizing-breakpoints-large) {
        order: 1;
      }
    }
  }

  // This is to place the .image div after large breakpoint
  > div:nth-child(4) {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      order: 4;
    }
  }

  .image {
    display: flex;
    position: relative;
    z-index: var(--emu-common-other-z-index-layer);
    margin-block-end: 30px;
    margin-inline-start: -15px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-block-end: 20px;
      padding-inline-end: 0;
      padding-inline-start: 0;
      width: 75%;
      margin-inline-start: auto;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-block-end: 60px;
      max-width: var(--emu-semantic-sizing-content-max-width);
    }

    &::after {
      content: "";
      background-image: linear-gradient(-70deg, var(--emu-semantic-colors-secondary-red-100) 0%, var(--emu-semantic-colors-secondary-blue-300) 100%);
      inset-block-end: 15px;
      position: absolute;
      inset-block-start: 40px;
      inset-inline-end: -15px;
      inset-block-end: -15px;
      width: 67%;
      z-index: var(--emu-common-other-z-index-behind);

      @include mq($emu-semantic-sizing-breakpoints-large) {
        inset-block-end: -20px;
        inset-block-start: 65px;
        width: 40%;
        inset-inline-start: 77%;
      }
    
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        inset-block-end: -30px;
        inset-block-start: 90px;
        width: 32vw;
      }
    }

    .emu-image {
      display: flex;
      align-items: center;
      aspect-ratio: 180/101;
      overflow: hidden;
      max-width: 820px;
      max-height: 470px;
      animation-delay: 0.3s;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      opacity: 0;
      height: fit-content;
      width: 100%;

      .cmp-image__image {
        height: 100%;
      }
    }
  }

  > .title + .text + .image {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      order: 4;
    }
  }

  > .container {
    position: relative;
    width: 100%;
  }

  .button {
    inset-block-end: 0;
    inset-inline-start: 0;
    position: absolute;
    inset-inline-end: 0;
    inset-block-start: 0;
    z-index: var(--emu-common-other-z-index-layer);

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-inline-start: auto;
      max-width: 725px;
    }

    .c-qa__button {
      align-items: center;
      backdrop-filter: blur(10px);
      background-color: var(--emu-semantic-colors-transparent-white-60);
      border: 0;
      border-radius: 50%;
      inset-block-end: 60px;
      color: var(--emu-semantic-colors-primary-darkblue);
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding-block-end: 18px;
      padding-inline-start: 27px;
      padding-inline-end: 27px;
      padding-block-start: 18px;
      position: absolute;
      inset-inline-end: 30px;
      transition: background-color 0.2s ease;

      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        inset-block-end: 90px;
        padding-block-end: 49px;
        padding-inline-start: 56px;
        padding-inline-end: 56px;
        padding-block-start: 49px;
        inset-inline-end: 60px;
      }

      &:hover {
        &::before {
          border-inline-start-color: var(--emu-semantic-colors-primary-lightblue);
        }
      }

      &::before {
        border: 11px solid var(--emu-common-colors-transparent);
        border-left: 16px solid var(--emu-semantic-colors-primary-darkblue);
        border-right: none;
        content: "";
        display: block;
        transform: translate(20%, 5%);
        width: 0;

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          border-bottom-width: 22px;
          border-left-width: 30px;
          border-top-width: 22px;
        }
      }
    }

    .cmp-button__text {
      font-size: 0;
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .c-qacontainer {
    &.text-highlighted {
      > .text {
        h5 {
          font-family: var(--emu-semantic-font-families-body-assistant);
        }
      }
    }
  }
}