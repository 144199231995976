[dir="rtl"] {
  .c-faqshort {
    .aaaem-accordion {
      &__header {
        &.-ba-is-active {
          .aaaem-accordion__icon::before {
            transform: rotate(-135deg);
          }
        }
      }

      &__icon {
        &::before {
          transform: translateY(0)rotate(45deg);
        }
      }
    }
  }

  .c-mediatext.c-mediatext--texthighlighted {
    > .text {
      .text-highlighted {
        h5::before {
          transform: translateX(100%);
        }
      }
    }
  }

  .c-qacontainer.text-highlighted {
    > .text {
      h5::before {
        transform: translateX(100%);
      }
    }
  }

  .c-testimonialmedia {
    .c-testimonialmedia__text {
      &::before {
        transform: translateX(100%);
      }

      &::after {
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          transform: translateX(-100%);
        }
      }

      .cmp-text {
        &::before {
          transform: translateX(calc(100% - 30px));
        }

        blockquote {
          &::before {
            transform: translateX(calc(100% + 30px));
          }
        }
      }
    }

    .c-testimonialmedia__modal {
      .modal-content {
        .main-close {
          &:hover {
            span {
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
  }

  footer {
    &::before {
      transform: scaleX(-1);
    }

    .c-footer {
      .container {
        .aaaem-container__position-static {
          .c-footer-logos {
            @include mq($emu-semantic-sizing-breakpoints-large) {
              align-items: end;
            }

            .image {
              svg {
                @include mq($emu-semantic-sizing-breakpoints-large) {
                  margin-inline-end: 20px;
                  margin-inline-start: 0;
                }
              }
            }
          }

          .aem-Grid {
            .aem-GridColumn.aem-GridColumn--default--12:first-child {
              @include mq($emu-semantic-sizing-breakpoints-large) {
                float: left;
              }
            }
          }
        }
      }
    } 
  }

  .c-navigation--mobile {
    .modal-wrapper {
      transform: translateX(-100%);
    }
  }

  .c-bulletpoints {
    .cmp-text {
      direction: rtl;
    }
  }
}