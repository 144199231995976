.c-faqshort {
  margin-block-end: 60px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-block-start: 60px;
  max-width: 730px;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  position: relative;

  @include mq($emu-semantic-sizing-breakpoints-xx-large) {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  > .text {
    margin-block-end: 20px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-block-end: 30px;
    }
  }

  .cmp-title__text {
    margin-block-end: 20px;

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      margin-block-end: 40px;
      margin-block-start: 40px;
    }
  }

  .aaaem-accordion {
    margin-block-end: 20px;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block-start: 0;

    &__header {
      border-block-end: 0;
      border-block-start: 1px solid var(--emu-semantic-colors-primary-darkblue);
      padding-block-end: 10px;
      padding-inline-start: 0;
      padding-inline-end: 0;
      padding-block-start: 10px;

      &.-ba-is-active {
        .aaaem-accordion__icon::before {
          background-color: var(--emu-common-colors-transparent);
          transform: rotate(135deg);
        }
      }
    }

    &__title {
      color: var(--emu-semantic-colors-primary-darkblue);
      font-family: var(--emu-semantic-font-families-body);
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        font-size: 22px;
      }
    }

    &__icon {
      align-self: auto;
      display: block;
      height: 13px;
      position: relative;
      width: 13px;

      &::before {
        background-color: var(--emu-common-colors-transparent);
        border-block-end: 2px solid var(--emu-common-colors-black);
        border-end-start-radius: 0;
        border-end-end-radius: 0;
        border-inline-start: 2px solid var(--emu-common-colors-black);
        border-start-start-radius: 0;
        border-start-end-radius: 0;
        content: "";
        height: 13px;
        inset-inline-start: unset;
        position: absolute;
        inset-inline-end: 8px;
        inset-block-start: 2px;
        transition: 0.3s;
        transform: translateY(0) rotate(-45deg);
        width: 13px;
        will-change: transform;
      }

      &::after {
        display: none;
      }
    }

    &__panel {
      &.-ba-is-active {
        max-height: none !important;
        /* important needed to overwrite a max-height set with JS that is too small */
      }

      .js-badger-accordion-panel-inner {
        padding-inline-start: 0;
        padding-inline-end: 45px;
        padding-block-start: 0;
      }
    }

    .cmp-text {
      p,
      ul,
      small {
        margin-block-end: 20px;
      }

      ul {
        padding-inline-start: 0;
      }

      li {
        padding-inline-start: 10px;
        position: relative;

        &::before {
          content: "·";
          inset-inline-start: 0;
          position: absolute;
          vertical-align: middle;
        }

        &::marker {
          display: none;
        }
      }
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .c-faqshort {
    .aaaem-accordion {
      &__title {
        font-family: var(--emu-semantic-font-families-body-assistant);
      }
    }
  }
}