header {
  &.header {
    background-color: var(--emu-common-colors-transparent);
    inset-inline-start: 0;
    position: absolute;
    inset-inline-end: 0;
    inset-block-start: 0;
    transition: background-color 0.2s;
    width: 100%;
    z-index: var(--emu-common-other-z-index-header);

    &.header--sticky {
      animation: moveDown 0.5s ease;
      background-color: var(--emu-semantic-colors-primary-lightblue);
      position: fixed;
    }

    .header {
      &__content {
        margin-inline-start: auto;
      }
    
      &__logo {
        height: 40px;

        .emu-image__link {
          display: inline-block;
        }

        svg {
          display: block;
          height: 40px;
          width: auto;
        }
      }
    }

    .cmp-container-header {
      align-items: center;
      display: flex;
      height: 100%;
      margin-inline-start: auto;
      margin-inline-end: auto;
      max-width: var(--emu-semantic-sizing-content-max-width);
      padding-inline-start: 15px;
      padding-block-start: 10px;
      padding-block-end: 10px;
      padding-inline-end: 15px;
  
      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        height: auto;
        padding-block-end: 25px;
        padding-block-start: 25px;
      }
      
      @include mq($emu-semantic-sizing-breakpoints-xx-large) {
        padding-inline-start: 0;
        padding-inline-end: 0;
      }
    }

    .c-header-burger.aaaem-button {
      align-items: center;
      height: 40px;
      padding-inline-start: 20px;
      padding-inline-end: 20px;

      &:active {
        background-color: var(--emu-common-colors-transparent);
        outline: none;
      }

      .cmp-button__text {
        display: inline-block;
        padding-block-start: 6px;
      }

      svg {
        height: 17px;
        margin-inline-start: 7px;
        margin-block-start: 1px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        display: none;
      }
    }
  }
}
