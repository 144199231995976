.scrollreveal {
  margin-block-end: 60px;
  margin-block-start: 60px;
  position: relative;

  .emu {
    &-scroll-reveal {
      max-width: 100%;
      width: 100vw;

      &__container {
        height: 600px;
        padding-block-start: var(--emu-component-containers-scroll-reveal-padding-top-narrow);
        padding-block-end: var(--emu-component-containers-scroll-reveal-padding-bottom-narrow);

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          height: 480px;
        }

        @include mq($emu-semantic-sizing-breakpoints-large) {
          height: 435px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          padding-block-start: var(--emu-component-containers-scroll-reveal-padding-top-narrow);
          padding-block-end: var(--emu-component-containers-scroll-reveal-padding-bottom-narrow);
          height: 405px;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          padding-block-start: var(--emu-component-containers-scroll-reveal-padding-top-wide);
          padding-block-end: var(--emu-component-containers-scroll-reveal-padding-bottom-wide);
          height: 600px;
        }

        &::before {
          content: "";
          position: absolute;
          background-image: linear-gradient(199deg, var(--emu-semantic-colors-secondary-red-100) 0%, var(--emu-semantic-colors-secondary-blue-300) 100%);
          height: 100%;
          width: calc(16vw - 15px);
          transition: 0.8s ease;
          inset-block-start: 0;
        }
      }

      &__content-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          flex-direction: row;
        }
      }

      &__static-content-container {
        max-width: 100%;
        padding-inline-start: 15px;
        padding-inline-end: 15px;
        padding-block-end: 40px;
        margin-block-start: 0;
        margin-inline-end: 0;
        margin-block-end: 0;
        margin-inline-start: 0;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          max-width: 475px;
        }
      }

      &__progressbar-container {
          margin-block-end: 0;
          margin-block-start: 22px;
      }

      &__slides {
        max-width: 100%;
        margin-inline-start: 70px;
        padding-inline-start: 15px;
        padding-inline-end: 15px;

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          max-width: 665px;
          margin-inline-start: 96px;
        }

        @include mq($emu-semantic-sizing-breakpoints-large) {
          max-width: 55%;
          margin-block-start: 0;
          margin-inline-end: 0;
          margin-block-end: 0;
          margin-inline-start: 0;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          max-width: 540px;
        }
      }

      .emu-title__text {
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);
      }

      .card .emu-card__title .emu-title__text {
        font-family: var(--emu-semantic-font-families-body);

        sup {
          font-size: 10px;
        }
      }
    }

    &-card {
      display: flex;
      grid-column: 1/1;
      grid-row: 1/1;
      margin-block-end: 60px;

      &__background-image {
        margin-inline-end: 15px;

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          margin-inline-end: 25px;
        }
      }

      &__wrapper {
        margin-block-start: 0;
        margin-inline-end: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        padding-inline-start: 15px;

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          margin-block-start: 0;
          margin-inline-end: 0;
          margin-block-end: 20px;
          margin-inline-start: 0;
        }
      }

      &__title-wrapper {
        margin-block-end: 15px;

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          margin-block-end: 20px;
        }
      }

      footer {
        background-color: var(--emu-semantic-colors-none);
      }
    }
  }

  .card[aria-hidden=false] {
    .emu-image {
      animation: fadeIn 0.2s;
    }
  }

  .card[aria-hidden=true] {
    .emu-image {
      opacity: 0;
      transition: 0.8s ease;
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .scrollreveal {
    .emu {
      &-scroll-reveal {
        h2.emu-title__text.cmp-title__text {
          font: var(--emu-component-containers-scroll-reveal-header-assistant-text-typography-narrow);        
          @include mq($emu-semantic-sizing-breakpoints-large) {
            font: var(--emu-component-containers-scroll-reveal-header-assistant-text-typography-wide);        
          } 
        } 
        .card .emu-card__title .emu-title__text {
          font-family: var(--emu-semantic-font-families-body-assistant);
          font: var(--emu-component-containers-scroll-reveal-cards-header-assistant-text-typography-narrow);
          @include mq($emu-semantic-sizing-breakpoints-large) {
            font: var(--emu-component-containers-scroll-reveal-cards-header-assistant-text-typography-wide);
          }
        }
      }
    }
  }
}