.c-topicnavigation {
  &::after {
    background: var(--emu-semantic-colors-secondary-grey-300);
    inset-block-end: 0;
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
  }

  &__section {
    overflow: hidden;
  }
  
  &__wrapper {
    position: sticky;
    inset-block-start: 60px;
    z-index: var(--emu-common-other-z-index-overlay);

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      inset-block-start: 90px;
    }
  }

  &__container {
    @include mq($emu-semantic-sizing-breakpoints-large) {
      display: flex;
      justify-content: space-between;
      margin-inline-start: auto;
      margin-inline-end: auto;
      max-width: var(--emu-semantic-sizing-content-max-width);
    }

    > .text {
      .cmp-text {
        display: none;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          color: var(--emu-semantic-colors-text-light);
          display: block;
          font-size: 18px;
          line-height: 32px;
          margin-inline-start: 15px;
          margin-inline-end: 35px;
          opacity: 0.6;
          padding-block-end: 16px;
          padding-block-start: 16px;
          white-space: nowrap;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          margin-inline-start: 0;
        }
      }
    }
  }

  .emu-navigation {
    &__content-wrapper {
      background-color: var(--emu-semantic-colors-none);
      height: 100%;
      position: relative;

      .emu-navigation {
        background-color: var(--emu-semantic-colors-none);
        height: 100%;
      }
    }

    &__menu--level-0 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 100%;
      overflow-x: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      flex-shrink: 0;

      &:not(:first-of-type) {
        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          margin-inline-start: 46px;
        }
      }

      a {
        border: 0;
        color: var(--emu-semantic-colors-text-light);
        font-size: 14px;
        margin-block-end: 3px;
        margin-inline-start: 15px;
        margin-inline-end: 15px;
        margin-block-start: 20px;
        padding-block-end: 15px;
        padding-inline-start: 0;
        padding-inline-end: 0;
        padding-block-start: 0;
        position: relative;

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          font-size: 16px;
        }

        &:hover {
          background-color: var(--emu-semantic-colors-none);
          color: var(--emu-semantic-colors-text-light);

          @include mq($emu-semantic-sizing-breakpoints-large) {
            color: var(--emu-semantic-colors-text-light);

            &::after {
              transform: scaleX(1);
            }
          }
        }

        &::after {
          background-color: var(--emu-semantic-colors-primary-darkblue);
          inset-block-end: -7px;
          content: "";
          display: block;
          height: 2px;
          inset-inline-start: 0;
          position: absolute;
          transform: scaleX(0);
          transition: 0.3s ease;
          width: 100%;

          @include mq($emu-semantic-sizing-breakpoints-xx-large) {
            inset-block-end: -4px;
          }
        }
      }

      &--active {
        a {
          color: var(--emu-semantic-colors-text-light);

          &:hover {
            color: var(--emu-semantic-colors-text-light);
          }

          &::after {
            transform: scaleX(1);
          }
        }
      }
    }
  }
}