.icl-feed-container {
  .clinics {
    &__list {
      list-style: none;
      min-height: 100vh;
      overflow-anchor: none;
      padding-inline-start: 0;

      @include aem-editor-view() {
        min-height: auto;
      }
    }

    &__show-more {
      display: flex;
      justify-content: center;
      margin-block-end: 60px;
      margin-block-start: 40px;
    }

  }

  .item {
    margin-block-end: 20px;
    margin-block-start: 40px;
    padding-block-end: 20px;
    padding-block-start: 20px;
    padding-inline-end: 20px;
    padding-inline-start: 20px;

    &:first-child {
      margin-block-start: 0;
      padding-block-start: 0;
    }

    &::after {
      border-block-end: 1px solid var(--emu-semantic-colors-transparent-grey-300);
      content: "";
      display: block;
      inset-block-end: 0;
      margin-block-end: -20px;
      inset-inline-start: 20px;
      position: absolute;
      width: calc(100% - 40px); // -40px is the top margin value
    }

    &--active {
      background-color: var(--emu-semantic-colors-transparent-blue-10);
    }

    &__image-container {
      margin-block-end: 10px;
      padding-block-start: 62.85%;
    }

    &__description {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-inline-start: 0;
    }

    &__distance,
    &__street,
    &__city,
    &__zip-code {
      display: inline-block;
      padding-inline-end: 5px;
    }

    &__image {
      inset-block-start: 0;
      inset-inline-start: 0;
      position: absolute;
    }

    &__heading {
      color: var(--emu-semantic-colors-primary-lightblue);
      cursor: pointer;
      font-family: var(--emu-semantic-font-families-body);
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      margin-block-end: 5px;
      margin-block-start: 0px;
      margin-inline-end: 0px;
      margin-inline-start: 0px;
    }

    &__description {
      color: var(--emu-semantic-colors-text-light);
      font-size: 14px;
      line-height: 20px;
      margin-block-end: 20px;
    }

    &__buttons-container {
      display: flex;
    }

    &__button {
      position: relative;
    }

    &__button-icon {
      inset-block-start: 50%;
      inset-inline-start: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    &__link {
      color: var(--emu-component-actions-button-text-color-primary-filled-dark);
      cursor: pointer;
      display: inline-block;
      font-family: var(--emu-semantic-font-families-body);
      font-size: 18px;
      height: 40px;
      line-height: 32px;
      min-width: 40px;
      overflow: hidden;
      position: relative;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__link-show-more,
    &__link-website,
    &__link-phone {
      background-color: var(--emu-component-actions-button-color-background-primary-filled-dark);
      border-radius: 25px;
      padding-block-end: 2px;
      padding-block-start: 6px;
      padding-inline-end: 20px;
      padding-inline-start: 20px;
      margin-inline-end: 10px;

      &:active {
        background-color: var(--emu-component-actions-button-color-background-primary-filled-active-dark);
      }

      &:focus {
        box-shadow: 0 0 4px 4px var(--emu-semantic-colors-secondary-blue-200);
      }

      &:hover {
        background: linear-gradient(to right, var(--emu-semantic-colors-secondary-blue-200), var(--emu-semantic-colors-primary-lightblue));
      }
    }

    &__link-clinic {
      border: 1px solid var(--emu-semantic-colors-primary-lightblue);
      border-radius: 50%;
      padding-block-end: 8px;
      padding-block-start: 8px;
      padding-inline-end: 8px;
      padding-inline-start: 8px;
      margin-inline-start: auto;

      &:focus {
        box-shadow: 0 0 4px 4px var(--emu-semantic-colors-secondary-blue-200);
      }

      &:hover {
        border-color: var(--emu-semantic-colors-primary-lightblue);
        color: var(--emu-common-colors-white);
      }
    }
  }

  .no-results {
    inset-block-start: 50%;
    inset-inline-start: 0;
    height: auto;
    padding-block-end: 0px;
    padding-block-start: 0px;
    padding-inline-end: 20px;
    padding-inline-start: 20px;
    position: absolute;
    transform: translateY(-50%);
    width: 100%;

    &__icon-container {
      display: flex;
      height: 6rem;
      justify-content: center;

      img {
        height: 100%;
      }
    }

    &__heading {
      margin-block-end: 15px;
      margin-block-start: 15px;
      margin-inline-end: 0px;
      margin-inline-start: 0px;
      text-align: center;
    }

    &__text {
      text-align: center;
    }
  }
}

.clinics {
  &__results {
    color: var(--emu-semantic-colors-text-light);
    font-size: 14px;
    line-height: 20px;
    padding-block-end: 20px;
    padding-block-start: 20px;
    padding-inline-end: 20px;
    padding-inline-start: 20px;
    text-align: center;

    &--number {
      display: inline-block;
      padding-inline-end: 8px;
    }

    &--location {
      display: inline-block;
    }

    &--reset {
      background-color: initial;
      border: none;
      cursor: pointer;
      display: inline-block;
      padding-block-end: 0;
      padding-block-start: 0;
      padding-inline-end: 0;
      padding-inline-start: 0;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }

    .tabpanel__container-map & {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        display: none;
      }
    }
  }
}
// Country overrides 
html[lang="he"],
html[lang="he_il"]{
  .icl-feed-container {
    .item {
      &__heading {
        font-family: var(--emu-semantic-font-families-body-assistant);
      }
      &__link {
        font-family: var(--emu-semantic-font-families-body-assistant);
      }
    }

  }
}