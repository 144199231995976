.c-keytakeaways {
  background-color: var(--emu-semantic-colors-transparent-blue-10);
  margin-block-end: 40px;
  padding-block-end: 40px;
  padding-inline-start: 40px;
  padding-inline-end: 40px;
  padding-block-start: 40px;

  @include mq($emu-semantic-sizing-breakpoints-large) {
    padding-block-end: 40px;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    margin-block-end: 60px;
    padding-block-end: 60px;
    padding-inline-start: 60px;
    padding-inline-end: 60px;
    padding-block-start: 60px;
  }

  &__container {
    background-color: var(--emu-common-colors-transparent);
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: var(--emu-semantic-sizing-content-max-width);

    @include mq($emu-semantic-sizing-breakpoints-large) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    > .title {
      @include mq($emu-semantic-sizing-breakpoints-large) {
        flex: 0 0 100%;
        padding-block-end: 35px;
        padding-inline-start: 15px;
        padding-inline-end: 15px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        padding-inline-start: 0;
        padding-inline-end: 0;
      }
    }

    > .text:not(:first-child) {
      position: relative;

      &:not(:last-of-type) {
        @include mq($emu-semantic-sizing-breakpoints-large) {
          margin-block-end: 0;
        }
      }

      &::after {
        background-color: var(--emu-semantic-colors-primary-darkblue);
        inset-block-end: 0;
        content: "";
        display: block;
        height: 2px;
        inset-inline-start: 0;
        position: absolute;
        width: 30px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          inset-inline-start: 15px;
        }

        @include mq($emu-semantic-sizing-breakpoints-xx-large) {
          inset-inline-start: 0;
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-large) {
        flex: 0 0 33%;
      }
    }
  }

  .cmp-title__text {
    margin-block-end: 30px;
  }

  .cmp-text {
    margin-block-end: 30px;
    padding-block-end: 5px;
    position: relative;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-inline-start: 0;
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-block-end: 5px;
      margin-inline-end: 8%;
      padding-block-end: 10px;
    }

    @include mq($emu-semantic-sizing-breakpoints-xx-large) {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }

    p {
      font-size: 18px;
      line-height: 25px;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}
