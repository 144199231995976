.p-404 {
  header.header {
    background-color: var(--emu-semantic-colors-primary-lightblue);
  }

  .p-404-container {
    margin-block-end: 105px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-block-start: 120px;
    max-width: var(--emu-semantic-sizing-content-max-width);
    padding-inline-start: 15px;
    padding-inline-end: 15px;


    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      margin-block-start: 150px;
    }

    > .text,
    > .button {
      text-align: center;
    }

    h2 {
      margin-block-end: 40px;
    }

    h3 {
      margin-block-end: 65px;
    }

    p {
      margin-block-end: 30px;
    }
  }
}