/* Resets the CSS physical properties set in aaaem-common and adds their logical counterparts */

.aaaem-accordion {
  dl {
    padding-bottom: unset;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    padding-block-end: 0;
    border-start-start-radius: var(--emu-component-lists-accordion-border-radius-top-left);
    border-start-end-radius: var(--emu-component-lists-accordion-border-radius-top-right);
    border-end-start-radius: var(--emu-component-lists-accordion-border-radius-bottom-left);
    border-end-end-radius: var(--emu-component-lists-accordion-border-radius-bottom-right);
  }

  dt {
    &:first-of-type {
      margin-top: unset;
      margin-block-start: 0;
    }

    &:first-of-type {
      margin-top: unset;
      margin-block-start: 0;
    }
  }

  dd {
    .js-badger-accordion-panel-inner {
      padding-bottom: unset;
      padding-left: unset;
      padding-right: unset;
      padding-top: unset;
      padding-block-end: var(--emu-component-lists-accordion-item-body-padding-bottom-narrow);
      padding-inline-start: var(--emu-component-lists-accordion-item-body-padding-left-narrow);
      padding-inline-end: var(--emu-component-lists-accordion-item-body-padding-right-narrow);
      padding-block-start: var(--emu-component-lists-accordion-item-body-padding-top-narrow);

      @include mq($emu-semantic-sizing-breakpoints-medium) {
        padding-bottom: unset;
        padding-left: unset;
        padding-right: unset;
        padding-top: unset;
        padding-block-end: var(--emu-component-lists-accordion-item-body-padding-bottom-wide);
        padding-inline-start: var(--emu-component-lists-accordion-item-body-padding-left-wide);
        padding-inline-end: var(--emu-component-lists-accordion-item-body-padding-right-wide);
        padding-block-start: var(--emu-component-lists-accordion-item-body-padding-top-wide);
      }
    }
  }

  .aaaem-accordion {
    padding-bottom: unset;
    margin-left: unset;
    margin-right: unset;
    margin-top: unset;
    padding-block-end: var(--emu-component-lists-accordion-margin-bottom-narrow);
    margin-inline-start: var(--emu-component-lists-accordion-margin-left-narrow);
    margin-inline-end: var(--emu-component-lists-accordion-margin-right-narrow);
    margin-block-start: var(--emu-component-lists-accordion-margin-top-narrow);

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      margin-bottom: unset;
      margin-left: unset;
      margin-right: unset;
      margin-top: unset;
      margin-block-end: var(--emu-component-lists-accordion-margin-bottom-wide);
      margin-inline-start: var(--emu-component-lists-accordion-margin-left-wide);
      margin-inline-end: var(--emu-component-lists-accordion-margin-right-wide);
      margin-block-start: var(--emu-component-lists-accordion-margin-top-wide);
    }

    &__header {
      border-bottom-color: unset;
      border-bottom-width: unset;
      border-block-end-color: var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
      border-block-end-width: var(--emu-component-lists-accordion-item-border-width-bottom-open);
      padding-bottom: unset;
      padding-left: unset;
      padding-right: unset;
      padding-top: unset;
      padding-block-end: var(--emu-component-lists-accordion-item-header-padding-bottom-narrow);
      padding-inline-start: var(--emu-component-lists-accordion-item-header-padding-left-narrow);
      padding-inline-end: var(--emu-component-lists-accordion-item-header-padding-right-narrow);
      padding-block-start: var(--emu-component-lists-accordion-item-header-padding-top-narrow);

      @include mq($emu-semantic-sizing-breakpoints-medium) {
        padding-bottom: unset;
        padding-left: unset;
        padding-right: unset;
        padding-top: unset;
        padding-block-end: var(--emu-component-lists-accordion-item-header-padding-bottom-narrow);
        padding-inline-start: var(--emu-component-lists-accordion-item-header-padding-left-narrow);
        padding-inline-end: var(--emu-component-lists-accordion-item-header-padding-right-narrow);
        padding-block-start: var(--emu-component-lists-accordion-item-header-padding-top-narrow);
      }

      &.-ba-is-active {
        border-bottom-color: unset;
        border-block-end-color: var(--emu-component-lists-accordion-item-color-border-bottom-open-light);

        .aaaem-accordion__icon {
          &::before {
            transform: rotate(90deg);
          }
        }
      }
    }

    &__button {
      text-align: start;
    }

    &__icon {
      margin-left: unset;
      margin-inline-start: var(--emu-component-lists-accordion-item-header-icon-spacing);

      &::before,
      &::after {
        left: unset;
        right: unset;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        inset-inline-start: 0;
        inset-inline-end: 0;
        border-start-start-radius: var(--emu-component-lists-accordion-item-header-icon-border-radius-top-left);
        border-start-end-radius: var(--emu-component-lists-accordion-item-header-icon-border-radius-top-right);
        border-end-start-radius: var(--emu-component-lists-accordion-item-header-icon-border-radius-bottom-left);
        border-end-end-radius: var(--emu-component-lists-accordion-item-header-icon-border-radius-bottom-right);
      }

      &::after {
        transform: rotate(90deg);
      }
    }
  }

  .accordion {
    &.dark-theme {
      .aaaem-accordion {
        &__header {
          border-bottom-color: unset;
          border-block-end-color: var(--emu-component-lists-accordion-item-color-border-bottom-close-dark);

          &.-ba-is-active {
            border-bottom-color: unset;
            border-block-end-color: var(--emu-component-lists-accordion-item-color-border-bottom-open-dark);
          }
        }
      }
    }
  }
}

.aaaem-button {
  &:not(a),
  &__primary-filled,
  &__primary-outline,
  &__secondary-filled,
  &__secondary-outline {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    padding-bottom: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: unset;
    border-end-start-radius: var(--emu-component-actions-button-border-radius-primary-filled-bottom-left);
    border-end-end-radius: var(--emu-component-actions-button-border-radius-primary-filled-bottom-right);
    border-start-start-radius: var(--emu-component-actions-button-border-radius-primary-filled-top-left);
    border-start-end-radius: var(--emu-component-actions-button-border-radius-primary-filled-top-right);
    padding-block-end: var(--emu-component-actions-button-padding-bottom-narrow);
    padding-inline-start: var(--emu-component-actions-button-padding-left-narrow);
    padding-inline-end: var(--emu-component-actions-button-padding-right-narrow);
    padding-block-start: var(--emu-component-actions-button-padding-top-narrow);

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      padding-bottom: unset;
      padding-left: unset;
      padding-right: unset;
      padding-top: unset;
      padding-block-end: var(--emu-component-actions-button-padding-bottom-wide);
      padding-inline-start: var(--emu-component-actions-button-padding-left-wide);
      padding-inline-end: var(--emu-component-actions-button-padding-right-wide);
      padding-block-start: var(--emu-component-actions-button-padding-top-wide);
    }

    svg,
    .aaaem-button__icon,
    .chevron {
      margin-right: unset;
      margin-inline-end: var(--emu-component-actions-button-icon-spacing);
    }
  }

  &.add-icon-right {

    svg,
    .aaaem-button__icon,
    .chevron {
      margin-left: unset;
      margin-right: unset;
      margin-inline-start: var(--emu-component-actions-button-icon-spacing);
      margin-inline-end: 0;
    }
  }

  &__secondary-filled {
    &.aaaem-button {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      border-end-start-radius: var(--emu-component-actions-button-border-radius-secondary-filled-bottom-left);
      border-end-end-radius: var(--emu-component-actions-button-border-radius-secondary-filled-bottom-right);
      border-start-start-radius: var(--emu-component-actions-button-border-radius-secondary-filled-top-left);
      border-start-end-radius: var(--emu-component-actions-button-border-radius-secondary-filled-top-right);

      svg,
      .aaaem-button__icon,
      .chevron {
        margin-right: unset;
        margin-inline-end: var(--emu-component-actions-button-icon-spacing);
      }
    }
  }

  &__primary-outline {
    &.aaaem-button {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      border-end-start-radius: var(--emu-component-actions-button-border-radius-primary-outline-bottom-left);
      border-end-end-radius: var(--emu-component-actions-button-border-radius-primary-outline-bottom-right);
      border-start-start-radius: var(--emu-component-actions-button-border-radius-primary-outline-top-left);
      border-start-end-radius: var(--emu-component-actions-button-border-radius-primary-outline-top-right);
    }
  }

  &__secondary-outline {
    &.aaaem-button {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      border-end-start-radius: var(--emu-component-actions-button-border-radius-secondary-outline-bottom-left);
      border-end-end-radius: var(--emu-component-actions-button-border-radius-secondary-outline-bottom-right);
      border-start-start-radius: var(--emu-component-actions-button-border-radius-secondary-outline-top-left);
      border-start-end-radius: var(--emu-component-actions-button-border-radius-secondary-outline-top-right);
    }
  }

  &.plus-minus-icon {
    .aaaem-button__icon {

      &::before,
      &::after {
        left: unset;
        right: unset;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        inset-inline-start: 0;
        inset-inline-end: 0;
        border-start-start-radius: var(--emu-component-actions-button-icon-border-radius-top-left);
        border-start-end-radius: var(--emu-component-actions-button-icon-border-radius-top-right);
        border-end-start-radius: var(--emu-component-actions-button-icon-border-radius-bottom-left);
        border-end-end-radius: var(--emu-component-actions-button-icon-border-radius-bottom-right);
      }

      &::after {
        transform: rotate(90deg);
      }
    }

    &.js-toggle-on {
      .aaaem-button__icon {
        &::before {
          transform: rotate(90deg);
        }
      }
    }
  }

  &.caret-icon {
    &.js-toggle-on {
      .chevron {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.aaaem-card {
  &__wrapper {
    margin-bottom: unset;
    margin-left: unset;
    margin-right: unset;
    margin-top: unset;
    padding-bottom: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: unset;
    margin-block-end: var(--emu-component-cards-card-margin-bottom-narrow);
    margin-inline-start: var(--emu-component-cards-card-margin-left-narrow);
    margin-inline-end: var(--emu-component-cards-card-margin-right-narrow);
    margin-block-start: var(--emu-component-cards-card-margin-top-narrow);
    padding-block-end: var(--emu-component-cards-card-padding-bottom-narrow);
    padding-inline-start: var(--emu-component-cards-card-padding-left-narrow);
    padding-inline-end: var(--emu-component-cards-card-padding-right-narrow);
    padding-block-start: var(--emu-component-cards-card-padding-top-narrow);

    @include mq("medium") {
      margin-bottom: unset;
      margin-top: unset;
      padding-bottom: unset;
      padding-right: unset;
      margin-block-end: var(--emu-component-cards-card-margin-bottom-wide);
      margin-left: var(--emu-component-cards-card-margin-left-wide);
      margin-right: var(--emu-component-cards-card-margin-right-wide);
      margin-block-start: var(--emu-component-cards-card-margin-top-wide);
      padding-block-end: var(--emu-component-cards-card-padding-bottom-wide);
      padding-left: var(--emu-component-cards-card-padding-left-wide);
      padding-inline-end: var(--emu-component-cards-card-padding-right-wide);
      padding-top: var(--emu-component-cards-card-padding-top-wide);
    }

    &.has-background-image {
      bottom: unset;
      left: unset;
      right: unset;
      top: unset;
      inset-block-end: 0;
      inset-inline-start: 0;
      inset-inline-end: 0;
      inset-block-start: 0;
    }
  }

  &__title-wrapper {
    .aaaem-button {

      svg,
      .aaaem-button__icon,
      .chevron {
        margin-right: unset;
        margin-inline-end: var(--emu-component-cards-card-header-button-icon-spacing);
      }
    }
  }

  &__toggle-cta {
    .aaaem-button .chevron {
      margin-right: unset;
      margin-inline-end: 0;
    }
  }

  &__body {
    @include aem-editor-view() {
      border-bottom: unset;
      border-top: unset;
      border-block-end: 1px dashed #dcdbdb;
      border-block-start: 1px dashed #dcdbdb;
    }
  }

  &__footer {
    @include aem-editor-view() {
      border-bottom: unset;
      border-block-end: 1px dashed #dcdbdb;
    }
  }
}

.aaaem-carousel {
  margin-top: unset;
  margin-bottom: unset;
  margin-left: unset;
  margin-right: unset;
  margin-block-start: var(--emu-component-containers-carousel-gap-horizontal-narrow);
  margin-block-end: var(--emu-component-containers-carousel-gap-horizontal-narrow);
  margin-inline-start: var(--emu-component-containers-carousel-gap-vertical-narrow);
  margin-inline-end: var(--emu-component-containers-carousel-gap-vertical-narrow);

  @include mq($emu-semantic-sizing-breakpoints-large) {
    margin-top: unset;
    margin-bottom: unset;
    margin-left: unset;
    margin-right: unset;
    margin-block-start: var(--emu-component-containers-carousel-gap-horizontal-wide);
    margin-block-end: var(--emu-component-containers-carousel-gap-horizontal-wide);
    margin-inline-start: var(--emu-component-containers-carousel-gap-vertical-wide);
    margin-inline-end: var(--emu-component-containers-carousel-gap-vertical-wide);
  }

  .visually-hidden {
    left: unset;
    inset-inline-start: -10000em;
  }

  &__indicators {
    margin-top: unset;
    margin-bottom: unset;
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
    margin-block-start: var(--emu-component-containers-carousel-indicators-margin-top-narrow);
    margin-block-end: var(--emu-component-containers-carousel-indicators-margin-bottom-narrow);
    padding-block-start: 0;
    padding-inline-end: 0;
    padding-block-end: 0;
    padding-inline-start: 0;

    @include mq($emu-semantic-sizing-breakpoints-large) {
      margin-top: unset;
      margin-bottom: unset;
      margin-block-start: var(--emu-component-containers-carousel-indicators-margin-top-wide);
      margin-block-end: var(--emu-component-containers-carousel-indicators-margin-bottom-wide);
    }
  }

  &__image-indicator-name {
    bottom: unset;
    left: unset;
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
    inset-block-end: 0;
    inset-inline-start: 0;
    padding-block-start: var(--emu-component-containers-carousel-indicators-text-padding-top-narrow);
    padding-inline-end: var(--emu-component-containers-carousel-indicators-text-padding-right-narrow);
    padding-block-end: var(--emu-component-containers-carousel-indicators-text-padding-bottom-narrow);
    padding-inline-start: var(--emu-component-containers-carousel-indicators-text-padding-left-narrow);
  }

  &__indicator {
    text-indent: 10000em;
  }

  &__action {
    bottom: unset;
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
    inset-block-end: 50%;
    padding-block-start: var(--emu-component-containers-carousel-controls-padding-top-narrow);
    padding-inline-end: var(--emu-component-containers-carousel-controls-padding-right-narrow);
    padding-block-end: var(--emu-component-containers-carousel-controls-padding-bottom-narrow);
    padding-inline-start: var(--emu-component-containers-carousel-controls-padding-left-narrow);

    @include mq($emu-semantic-sizing-breakpoints-large) {
      padding-top: unset;
      padding-right: unset;
      padding-bottom: unset;
      padding-left: unset;
      padding-block-start: var(--emu-component-containers-carousel-controls-padding-top-wide);
      padding-inline-end: var(--emu-component-containers-carousel-controls-padding-right-wide);
      padding-block-end: var(--emu-component-containers-carousel-controls-padding-bottom-wide);
      padding-inline-start: var(--emu-component-containers-carousel-controls-padding-left-wide);
    }

    &-next {
      right: unset;
      inset-inline-end: 0;
    }

    &-prev {
      left: unset;
      inset-inline-start: 0;
    }

    &-text {
      inset-inline-start: -10000em;
    }
  }

  .tns-outer+.tns-outer {
    margin-right: unset;
    margin-left: unset;
    margin-inline-end: auto;
    margin-inline-start: auto;

    .tns-controls {
      button {
        top: unset;
        padding-top: unset;
        padding-right: unset;
        padding-bottom: unset;
        padding-left: unset;
        inset-block-start: 50%;
        padding-block-start: var(--emu-component-containers-carousel-controls-padding-top-narrow);
        padding-inline-end: var(--emu-component-containers-carousel-controls-padding-right-narrow);
        padding-block-end: var(--emu-component-containers-carousel-controls-padding-bottom-narrow);
        padding-inline-start: var(--emu-component-containers-carousel-controls-padding-left-narrow);
        text-indent: 9999em;

        @include mq($emu-semantic-sizing-breakpoints-large) {
          padding-top: unset;
          padding-right: unset;
          padding-bottom: unset;
          padding-left: unset;
          padding-block-start: var(--emu-component-containers-carousel-controls-padding-top-wide);
          padding-inline-end: var(--emu-component-containers-carousel-controls-padding-right-wide);
          padding-block-end: var(--emu-component-containers-carousel-controls-padding-bottom-wide);
          padding-inline-start: var(--emu-component-containers-carousel-controls-padding-left-wide);
        }
      }
    }
  }
}

.tns-horizontal.tns-no-subpixel {
  >.tns-item {
    float: left;
  }
}

.tns-horizontal.tns-carousel.tns-no-subpixel {
  >.tns-item {
    margin-right: unset;
    margin-inline-end: -100%;
  }
}

.tns-no-calc {
  margin-left: unset;
  inset-inline-start: 0;
}

.tns-gallery {
  left: unset;
  inset-inline-start: 0;

  >.tns-item {
    left: unset;
    inset-inline-start: -100%;
  }

  >.tns-slide-active {
    left: unset !important;
    inset-inline-start: auto !important;
  }
}

.tns-visually-hidden {
  left: unset;
  inset-inline-start: -10000em;
}

.tns-t-ct {
  right: unset;
  inset-inline-end: 0;

  >div {
    float: left;
  }
}

.emu-comparison-slider {
  &__handle {
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
    padding-block-start: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-narrow);
    padding-inline-end: var(--emu-component-containers-comparison-slider-custom-handle-padding-right-narrow);
    padding-block-end: var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow);
    padding-inline-start: var(--emu-component-containers-comparison-slider-custom-handle-padding-left-narrow);

    @include mq('medium') {
      padding-top: unset;
      padding-right: unset;
      padding-bottom: unset;
      padding-left: unset;
      padding-block-start: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-wide);
      padding-inline-end: var(--emu-component-containers-comparison-slider-custom-handle-padding-right-wide);
      padding-block-end: var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide);
      padding-inline-start: var(--emu-component-containers-comparison-slider-custom-handle-padding-left-wide);
    }
  }

  &__arrow {
    border-left: unset;
    border-top: unset;
    border-inline-start: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow) solid var(--emu-component-containers-comparison-slider-custom-handle-arrow-color-light);
    border-block-start: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow) solid var(--emu-component-containers-comparison-slider-custom-handle-arrow-color-light);
    transform: rotate(-45deg);

    &--right {
      transform: rotate(135deg);
    }

    @include mq('medium') {
      border-left-width: unset;
      border-inline-start-width: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide);
      border-top-top: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide);
    }
  }
}

.aaaem-container {
  padding-top: unset;
  padding-right: unset;
  padding-bottom: unset;
  padding-left: unset;
  margin-top: unset;
  margin-right: unset;
  margin-bottom: unset;
  margin-left: unset;
  padding-block-start: var(--emu-component-containers-container-padding-top-narrow);
  padding-inline-end: var(--emu-component-containers-container-padding-right-narrow);
  padding-block-end: var(--emu-component-containers-container-padding-bottom-narrow);
  padding-inline-start: var(--emu-component-containers-container-padding-left-narrow);
  margin-block-start: var(--emu-component-containers-container-gap-horizontal-narrow);
  margin-inline-end: var(--emu-component-containers-container-gap-vertical-narrow);
  margin-block-end: var(--emu-component-containers-container-gap-horizontal-narrow);
  margin-inline-start: var(--emu-component-containers-container-gap-vertical-narrow);

  @include mq($emu-semantic-sizing-breakpoints-large) {
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
    margin-top: unset;
    margin-right: unset;
    margin-bottom: unset;
    margin-left: unset;
    padding-block-start: var(--emu-component-containers-container-padding-top-wide);
    padding-inline-end: var(--emu-component-containers-container-padding-right-wide);
    padding-block-end: var(--emu-component-containers-container-padding-bottom-wide);
    padding-inline-start: var(--emu-component-containers-container-padding-left-wide);
    margin-block-start: var(--emu-component-containers-container-gap-horizontal-wide);
    margin-inline-end: var(--emu-component-containers-container-gap-vertical-wide);
    margin-block-end: var(--emu-component-containers-container-gap-horizontal-wide);
    margin-inline-start: var(--emu-component-containers-container-gap-vertical-wide);
  }
}

.aaaem-embed {
  margin-top: unset;
  margin-right: unset;
  margin-bottom: unset;
  margin-left: unset;
  margin-block-start: var(--emu-component-containers-embed-gap-horizontal-narrow);
  margin-inline-end: var(--emu-component-containers-embed-gap-vertical-narrow);
  margin-block-end: var(--emu-component-containers-embed-gap-horizontal-narrow);
  margin-inline-start: var(--emu-component-containers-embed-gap-vertical-narrow);

  @include mq($emu-semantic-sizing-breakpoints-large) {
    margin-top: unset;
    margin-right: unset;
    margin-bottom: unset;
    margin-left: unset;
    margin-block-start: var(--emu-component-containers-embed-gap-horizontal-wide);
    margin-inline-end: var(--emu-component-containers-embed-gap-vertical-wide);
    margin-block-end: var(--emu-component-containers-embed-gap-horizontal-wide);
    margin-inline-start: var(--emu-component-containers-embed-gap-vertical-wide);
  }

  &__embeddable,
  &__oembed {
    &:not(.no-ratio) {
      padding-bottom: unset;
      padding-block-end: 56.25%;

      iframe {
        top: unset;
        left: unset;
        inset-block-start: 0;
        inset-inline-start: 0;
      }
    }
  }
}

[data-component='modal'] {
  .modal-wrapper {
    left: unset;
    top: unset;
    inset-inline-start: 0;
    inset-block-start: 0;
  }

  .modal-content {
    margin-top: unset;
    margin-right: unset;
    margin-bottom: unset;
    margin-left: unset;
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
    margin-block-start: 15%;
    margin-inline-end: auto;
    margin-block-end: 15%;
    margin-inline-start: auto;
    padding-block-start: var(--emu-component-layers-modal-body-padding-top-narrow);
    padding-inline-end: var(--emu-component-layers-modal-body-padding-right-narrow);
    padding-block-end: var(--emu-component-layers-modal-body-padding-bottom-narrow);
    padding-inline-start: var(--emu-component-layers-modal-body-padding-left-narrow);

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      padding-top: unset;
      padding-right: unset;
      padding-bottom: unset;
      padding-left: unset;
      padding-block-start: var(--emu-component-layers-modal-body-padding-top-wide);
      padding-inline-end: var(--emu-component-layers-modal-body-padding-right-wide);
      padding-block-end: var(--emu-component-layers-modal-body-padding-bottom-wide);
      padding-inline-start: var(--emu-component-layers-modal-body-padding-left-wide);
    }

    @include aem-editor-view() {
      margin-top: unset;
      margin-right: unset;
      margin-bottom: unset;
      margin-left: unset;
      margin-block-start: 20px;
      margin-inline-end: auto;
      margin-block-end: 20px;
      margin-inline-start: auto;
    }
  }

  .modal-cta-container {
    margin-top: unset;
    margin-block-start: var(--emu-component-layers-modal-actions-prompt-button-group-margin-top);
  }

  .close {
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
    right: unset;
    top: unset;
    padding-block-start: var(--emu-component-layers-modal-actions-close-padding-top);
    padding-inline-end: var(--emu-component-layers-modal-actions-close-padding-right);
    padding-block-end: var(--emu-component-layers-modal-actions-close-padding-bottom);
    padding-inline-start: var(--emu-component-layers-modal-actions-close-padding-left);
    inset-inline-end: 0;
    inset-block-start: 0;
  }
}

.emu-navigation {
  &__content-wrapper {
    left: unset;
    top: unset;
    inset-inline-start: 0;
    inset-block-start: 0;

    ul {
      padding-left: unset;
      padding-inline-start: 0;
    }

    nav {
      padding-top: unset;
      padding-right: unset;
      padding-bottom: unset;
      padding-left: unset;
      padding-block-end: var(--emu-component-lists-navigation-banner-padding-bottom-narrow);
      padding-inline-start: var(--emu-component-lists-navigation-banner-padding-left-narrow);
      padding-inline-end: var(--emu-component-lists-navigation-banner-padding-right-narrow);
      padding-block-start: var(--emu-component-lists-navigation-banner-padding-top-narrow);

      @include mq($emu-semantic-sizing-breakpoints-medium) {
        padding-top: unset;
        padding-right: unset;
        padding-bottom: unset;
        padding-left: unset;
        padding-block-end: var(--emu-component-lists-navigation-banner-padding-bottom-wide);
        padding-inline-start: var(--emu-component-lists-navigation-banner-padding-left-wide);
        padding-inline-end: var(--emu-component-lists-navigation-banner-padding-right-wide);
        padding-block-start: var(--emu-component-lists-navigation-banner-padding-top-wide);
      }

      a,
      span {
        padding-top: unset;
        padding-right: unset;
        padding-bottom: unset;
        padding-left: unset;
        padding-block-end: var(--emu-component-lists-navigation-text-padding-bottom-narrow);
        padding-inline-start: var(--emu-component-lists-navigation-text-padding-left-narrow);
        padding-inline-end: var(--emu-component-lists-navigation-text-padding-right-narrow);
        padding-block-start: var(--emu-component-lists-navigation-text-padding-top-narrow);

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          padding-top: unset;
          padding-right: unset;
          padding-bottom: unset;
          padding-left: unset;
          padding-block-end: var(--emu-component-lists-navigation-text-padding-bottom-wide);
          padding-inline-start: var(--emu-component-lists-navigation-text-padding-left-wide);
          padding-inline-end: var(--emu-component-lists-navigation-text-padding-right-wide);
          padding-block-start: var(--emu-component-lists-navigation-text-padding-top-wide);
        }
      }
    }
  }
}

.emu-scroll-reveal {
  &__container {
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
    padding-block-end: var(--emu-component-containers-scroll-reveal-padding-bottom-narrow);
    padding-inline-start: var(--emu-component-containers-scroll-reveal-padding-left-narrow);
    padding-inline-end: var(--emu-component-containers-scroll-reveal-padding-right-narrow);
    padding-block-start: var(--emu-component-containers-scroll-reveal-padding-top-narrow);

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      padding-top: unset;
      padding-right: unset;
      padding-bottom: unset;
      padding-left: unset;
      padding-block-end: var(--emu-component-containers-scroll-reveal-padding-bottom-wide);
      padding-inline-start: var(--emu-component-containers-scroll-reveal-padding-left-wide);
      padding-inline-end: var(--emu-component-containers-scroll-reveal-padding-right-wide);
      padding-block-start: var(--emu-component-containers-scroll-reveal-padding-top-wide);
    }
  }

  &__static-content-container {
    margin-top: unset;
    margin-right: unset;
    margin-bottom: unset;
    margin-left: unset;
    margin-block-end: var(--emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow);
    margin-inline-start: var(--emu-component-containers-scroll-reveal-static-section-margin-left-narrow);
    margin-inline-end: var(--emu-component-containers-scroll-reveal-static-section-margin-right-narrow);
    margin-block-start: var(--emu-component-containers-scroll-reveal-static-section-margin-top-narrow);

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      margin-top: unset;
      margin-right: unset;
      margin-bottom: unset;
      margin-left: unset;
      margin-block-end: var(--emu-component-containers-scroll-reveal-static-section-margin-bottom-wide);
      margin-inline-start: var(--emu-component-containers-scroll-reveal-static-section-margin-left-wide);
      margin-inline-end: var(--emu-component-containers-scroll-reveal-static-section-margin-right-wide);
      margin-block-start: var(--emu-component-containers-scroll-reveal-static-section-margin-top-wide);
    }
  }

  &__progressbar-container {
    margin-bottom: unset;
    margin-top: unset;
    margin-block-end: auto;
    margin-block-start: auto;
  }

  &__progressbar {
    left: unset;
    top: unset;
    inset-inline-start: -100%;
    inset-block-start: 0;
  }

  .card {
    .emu-card {
      &__wrapper {
        padding-top: unset;
        padding-right: unset;
        padding-bottom: unset;
        padding-left: unset;
        padding-block-end: var(--emu-component-containers-scroll-reveal-cards-padding-bottom-narrow);
        padding-inline-start: var(--emu-component-containers-scroll-reveal-cards-padding-left-narrow);
        padding-inline-end: var(--emu-component-containers-scroll-reveal-cards-padding-right-narrow);
        padding-block-start: var(--emu-component-containers-scroll-reveal-cards-padding-top-narrow);

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          padding-top: unset;
          padding-right: unset;
          padding-bottom: unset;
          padding-left: unset;
          padding-block-end: var(--emu-component-containers-scroll-reveal-cards-padding-bottom-wide);
          padding-inline-start: var(--emu-component-containers-scroll-reveal-cards-padding-left-wide);
          padding-inline-end: var(--emu-component-containers-scroll-reveal-cards-padding-right-wide);
          padding-block-start: var(--emu-component-containers-scroll-reveal-cards-padding-top-wide);
        }
      }

      &__title-wrapper,
      &__content-wrapper {
        margin-top: unset;
        margin-block-start: 0;
      }
    }
  }

  &__checkpoint {
    left: 0;
    bottom: unset;
    top: unset;
    inset-inline-start: 0;
    inset-block-end: 0;
    inset-block-start: auto;

    @media (min-width:768px) {
      bottom: unset;
      top: unset;
      inset-block-end: 0;
      inset-block-start: auto;
    }
  }

  &__vh {
    left: unset;
    top: unset;
    inset-inline-start: -200%;
    inset-block-start: 0;
  }
}

.aaaem-tabs {
  margin-top: unset;
  margin-right: unset;
  margin-bottom: unset;
  margin-left: unset;
  margin-block-start: var(--emu-component-containers-tabs-gap-horizontal-narrow);
  margin-block-end: var(--emu-component-containers-tabs-gap-horizontal-narrow);
  margin-inline-start: var(--emu-component-containers-tabs-gap-vertical-narrow);
  margin-inline-end: var(--emu-component-containers-tabs-gap-vertical-narrow);

  @include mq($emu-semantic-sizing-breakpoints-large) {
    margin-top: unset;
    margin-right: unset;
    margin-bottom: unset;
    margin-left: unset;
    margin-block-start: var(--emu-component-containers-tabs-gap-horizontal-wide);
    margin-block-end: var(--emu-component-containers-tabs-gap-horizontal-wide);
    margin-inline-start: var(--emu-component-containers-tabs-gap-vertical-wide);
    margin-inline-end: var(--emu-component-containers-tabs-gap-vertical-wide);
  }

  &__tab {
    a {
      padding-top: unset;
      padding-right: unset;
      padding-bottom: unset;
      padding-left: unset;
      border-top-width: unset;
      border-right-width: unset;
      border-bottom-width: unset;
      padding-block-start: var(--emu-component-containers-tabs-navigation-item-padding-top-narrow);
      padding-inline-end: var(--emu-component-containers-tabs-navigation-item-padding-right-narrow);
      padding-block-end: var(--emu-component-containers-tabs-navigation-item-padding-bottom-narrow);
      padding-inline-start: var(--emu-component-containers-tabs-navigation-item-padding-left-narrow);
      border-block-start-width: var(--emu-component-containers-tabs-navigation-item-border-width-top-default);
      border-inline-end-width: var(--emu-component-containers-tabs-navigation-item-border-width-right-default);
      border-block-end-width: var(--emu-component-containers-tabs-navigation-item-border-width-bottom-default);
      border-inline-start-width: var(--emu-component-containers-tabs-navigation-item-border-width-left-default);

      @include mq($emu-semantic-sizing-breakpoints-large) {
        padding-top: unset;
        padding-right: unset;
        padding-bottom: unset;
        padding-left: unset;
        padding-block-start: var(--emu-component-containers-tabs-navigation-item-padding-top-wide);
        padding-inline-end: var(--emu-component-containers-tabs-navigation-item-padding-right-wide);
        padding-block-end: var(--emu-component-containers-tabs-navigation-item-padding-bottom-wide);
        padding-inline-start: var(--emu-component-containers-tabs-navigation-item-padding-left-wide);
      }

      &[aria-selected="true"] {
        border-top-width: unset;
        border-right-width: unset;
        border-bottom-width: unset;
        border-left-width: unset;
        border-block-start: var(--emu-component-containers-tabs-navigation-item-border-width-top-active);
        border-inline-end-width: var(--emu-component-containers-tabs-navigation-item-border-width-right-active);
        border-block-end-width: var(--emu-component-containers-tabs-navigation-item-border-width-bottom-active);
        border-inline-start-width: var(--emu-component-containers-tabs-navigation-item-border-width-left-active);
      }
    }
  }

  &__tabpanel {
    .aaaem-container {
      padding-top: unset;
      padding-right: unset;
      padding-bottom: unset;
      padding-left: unset;
      margin-top: unset;
      margin-right: unset;
      margin-bottom: unset;
      margin-left: unset;
      padding-block-start: var(--emu-component-containers-tabs-content-padding-top-narrow);
      padding-inline-end: var(--emu-component-containers-tabs-content-padding-right-narrow);
      padding-block-end: var(--emu-component-containers-tabs-content-padding-bottom-narrow);
      padding-inline-start: var(--emu-component-containers-tabs-content-padding-left-narrow);
      margin-block-start: var(--emu-component-containers-tabs-content-gap-horizontal-narrow);
      margin-inline-end: var(--emu-component-containers-tabs-content-gap-vertical-narrow);
      margin-block-end: var(--emu-component-containers-tabs-content-gap-horizontal-narrow);
      margin-inline-start: var(--emu-component-containers-tabs-content-gap-vertical-narrow);

      @include mq($emu-semantic-sizing-breakpoints-large) {
        padding-top: unset;
        padding-right: unset;
        padding-bottom: unset;
        padding-left: unset;
        margin-top: unset;
        margin-right: unset;
        margin-bottom: unset;
        margin-left: unset;
        padding-block-start: var(--emu-component-containers-tabs-content-padding-top-wide);
        padding-inline-end: var(--emu-component-containers-tabs-content-padding-right-wide);
        padding-block-end: var(--emu-component-containers-tabs-content-padding-bottom-wide);
        padding-inline-start: var(--emu-component-containers-tabs-content-padding-left-wide);
        margin-block-start: var(--emu-component-containers-tabs-content-gap-horizontal-wide);
        margin-inline-end: var(--emu-component-containers-tabs-content-gap-vertical-wide);
        margin-block-end: var(--emu-component-containers-tabs-content-gap-horizontal-wide);
        margin-inline-start: var(--emu-component-containers-tabs-content-gap-vertical-wide);
      }
    }
  }
}

.aaaem-teaser {
  h1 {
    margin-bottom: unset;
    margin-top: unset;
    margin-block-end: var(--emu-component-cards-teaser-title-margin-bottom-narrow);
    margin-block-start: var(--emu-component-cards-teaser-title-margin-top-narrow);

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      margin-bottom: unset;
      margin-top: unset;
      margin-block-end: var(--emu-component-cards-teaser-title-margin-bottom-wide);
      margin-block-start: var(--emu-component-cards-teaser-title-margin-top-wide);
    }
  }

  .content-container {
    padding-top: unset;
    padding-right: unset;
    padding-bottom: unset;
    padding-left: unset;
    padding-block-end: var(--emu-component-cards-teaser-padding-bottom-narrow);
    padding-inline-start: var(--emu-component-cards-teaser-padding-left-narrow);
    padding-inline-end: var(--emu-component-cards-teaser-padding-right-narrow);
    padding-block-start: var(--emu-component-cards-teaser-padding-top-narrow);

    @include mq($emu-semantic-sizing-breakpoints-medium) {
      padding-top: unset;
      padding-right: unset;
      padding-bottom: unset;
      padding-left: unset;
      padding-block-end: var(--emu-component-cards-teaser-padding-bottom-narrow);
      padding-inline-start: var(--emu-component-cards-teaser-padding-left-narrow);
      padding-inline-end: var(--emu-component-cards-teaser-padding-right-narrow);
      padding-block-start: var(--emu-component-cards-teaser-padding-top-narrow);
    }

    .aaaem-teaser {
      &__description {
        margin-bottom: unset;
        margin-top: unset;
        margin-block-end: var(--emu-component-cards-teaser-description-margin-bottom-narrow);
        margin-block-start: var(--emu-component-cards-teaser-description-margin-top-narrow);

        @include mq($emu-semantic-sizing-breakpoints-medium) {
          margin-bottom: unset;
          margin-top: unset;
          margin-block-end: var(--emu-component-cards-teaser-description-margin-bottom-wide);
          margin-block-start: var(--emu-component-cards-teaser-description-margin-top-wide);
        }
      }

      &__action-container {
        margin-top: unset;
        margin-block-start: 16px;
      }
    }
  }

  .content {
    margin-right: unset;
    margin-left: unset;
    margin-inline-end: auto;
    margin-inline-start: auto;
  }

  &.has-video {
    .teaser-container {
      margin-bottom: unset;
      margin-block-end: 0;
    }

    .teaser-video-controls {
      bottom: unset;
      inset-block-end: 0;

      .video-controls-container {
        justify-content: flex-end;
      }

      .video-controls {
        margin-top: unset;
        margin-right: unset;
        margin-bottom: unset;
        margin-block-start: 40px;
        margin-inline-end: 52px;
        margin-block-end: 40px;
      }
    }
  }

  .disclaimer {
    margin-top: unset;
    margin-block-start: auto;
  }

  &.has-full-bleed-image,
  &.has-bg-image {
    .teaser-container {
      align-items: flex-end;
    }

    img {
      top: unset;
      left: unset;
      inset-block-start: 0;
      inset-inline-start: 0;
    }
  }

  .aaaem-teaser__video {
    top: unset;
    left: unset;
    inset-block-start: 0;
    inset-inline-start: 0;
  }

  .content-top-right {
    text-align: end;
    justify-content: flex-end;

    * {
      margin-left: unset;
      margin-inline-start: auto;
    }
  }

  .content-middle-left {
    margin-top: unset;
    margin-bottom: unset;
    margin-block-start: auto;
    margin-block-end: auto;
  }

  .content-middle-right {
    margin-top: unset;
    margin-bottom: unset;
    margin-block-start: auto;
    margin-block-end: auto;
    text-align: end;
    justify-content: flex-end;

    * {
      margin-left: unset;
      margin-inline-start: auto;
    }
  }

  .content-bottom-left {
    text-align: end;
    margin-top: unset;
    margin-block-start: auto;
  }

  .content-bottom-center {
    margin-top: unset;
    margin-block-start: auto;
  }

  .content-bottom-right {
    text-align: end;
    margin-top: unset;
    margin-block-start: auto;
    justify-content: flex-end;

    * {
      margin-left: unset;
      margin-inline-start: auto;
    }
  }

  .content-middle-left,
  .content-middle-right,
  .content-middle-center,
  .content-bottom-left,
  .content-bottom-center,
  .content-bottom-right {
    &+.image--disclaimer {
      margin-top: unset;
      margin-block-start: 0;
    }
  }

  .disclaimer-bottom-left {
    .image--disclaimer {
      text-align: start;
    }
  }

  .disclaimer-bottom-right {
    .image--disclaimer {
      text-align: end;
      justify-content: flex-end;
    }
  }
}

.aaaem-title__alignment {
  &-default {
    text-align: start;
  }

  &-right {
    text-align: end;
  }
}

.icl-detail {
  &__footer {
    bottom: unset;
    left: unset;
    inset-block-end: 0;
    inset-inline-start: 0;
  }
}

.icl-feed-container {
  .clinics {
    &__list {
      padding-left: unset;
      padding-inline-start: 0;
    }
  }

  .item {
    &__description {
      padding-left: unset;
      padding-inline-start: 0;
    }

    &__distance,
    &__street,
    &__city,
    &__zip-code {
      padding-right: unset;
      padding-inline-end: 5px;
    }
  }
}

.emu-location-services {
  label {
    padding-bottom: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: unset;
    padding-block-end: 0;
    padding-block-start: 0;
    padding-inline-end: 0;
    padding-inline-start: 0;
    margin-bottom: unset;
    margin-left: unset;
    margin-right: unset;
    margin-top: unset;
    margin-block-end: -1px;
    margin-block-start: -1px;
    margin-inline-end: -1px;
    margin-inline-start: -1px;
  }

  &__country-select-wrapper,
  &__location-input-wrapper {
    margin-bottom: unset;
    margin-block-end: 20px;
  }

  &__search-submit-cta {
    padding-bottom: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: unset;
    padding-block-end: 10px;
    padding-block-start: 10px;
    padding-inline-end: 20px;
    padding-inline-start: 20px;
  }
}